
import {
  ActionReducerMap,
  MetaReducer,
  ActionReducer,
  Store,
  Action
} from '@ngrx/store';
import { NEVER, Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { RequestTracker } from '@app/model/request-tracker';
import { sendRequestTracker } from '@reducers/widgets/actions';

import * as global from './global/reducer';
import * as global_actions from './global/actions';
import * as widgets from './widgets/reducer';
import * as widget_actions from './widgets/actions';
import * as pages from './pages/reducer';
import * as page_actions from './pages/actions';
import { asSerializable } from '@app/utils';
import { STYLE } from '@app/shared';

export const STORE_PERSISTED = 'wipo-portal-store';

// globals
export * from './global/selectors';
export * as ga from './global/actions';
// pages
export * from './pages/selectors';
export * as pa from './pages/actions';
// widget data
export * from './widgets/selectors';
export * as wa from './widgets/actions';

// join action types here if we have several
export type ActionTypes =
  | global_actions.ActionTypes
  | page_actions.ActionTypes
  | widget_actions.ActionTypes;

export interface State {
  global: global.State;
  pages: pages.State;
  widgets: widgets.State;
}
export const reducers: ActionReducerMap<State> = {
  global: global.reducer,
  pages: pages.reducer,
  widgets: widgets.reducer
};

// meta reducers could provide action loggers
interface ActionStats {
  type: string;
  count: number;
}
const SEC10 = 10000;
const actionStats: { timestamp: number, stats: ActionStats[] } = {
  timestamp: Date.now(),
  stats: []
};
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: State, action: Action) => {
    if (state?.global?.trace?.store) {
      console.log('%cstore', STYLE.META_TRACE, action);
    }
    return reducer(state, action);
  };
}
export const traceActions = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state: State, action: Action) => {
    if (state?.global?.trace?.named?.includes('actions')) {
      const count = actionStats.stats.length;
      if (actionStats.timestamp < (Date.now() - SEC10) && count > 0) {
        // show stats
        console.log(`%c${count}`, count > 100 ? STYLE.ACTIONS_ALERT : STYLE.ACTIONS_TRACE, 'store actions sent', 
        `(since ${new Date(actionStats.timestamp).toLocaleTimeString('en-us', {timeStyle: 'medium', hour12: false})})`);
        // re-initialize
        actionStats.timestamp = Date.now();
        actionStats.stats = [];
      }
      // update stats (or init)
      const stats = actionStats.stats.find(s => s.type === action.type) || { type: action.type, count: 0 };
      if (stats.count++ === 0) {
        // push on init
        actionStats.stats.push(stats);
      }
    }
    return reducer(state, action);
  };
}
export function initState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === '@ngrx/store/init') {
      const persistedState = JSON.parse(
        sessionStorage.getItem(STORE_PERSISTED)
      ) as State;
      if (state?.global?.trace?.store) { console.log('INIT STATE from', persistedState); }
      if (persistedState && persistedState.global) {
        // clean loading statii
        persistedState.global.loadingAuthenticationStatus = true;
        persistedState.global.pageLoaded = false;
        persistedState.global.currentPage = undefined;
        // clean cached authentication token, so we make sure to get a fresh one
        // window.sessionStorage.removeItem('id_token_claims_obj');
        // we implement refresh method instead, called from effects init script
      }
      return reducer(persistedState !== null ? persistedState : state, action);
    } else if (action.type === '@ngrx/effects/init') {
      if (state?.global?.trace?.store) { console.log('INIT EFFECTS with state', state); }
      return reducer(state, action);
    } else {
      return reducer(state, action);
    }
  };
}
export const metaReducers: MetaReducer<State>[] = [initState, debug, traceActions];

/* request tracking */
export const createTracker = (owner: string, name: string, url: string, method: string, data?: any): RequestTracker => {
  return { owner, name, url, data, method, start: Date.now(), requestLength: 0, processingLength: 0 };
}
export const finallyIfSuccesSendTracker = (tracker: RequestTracker, store: Store) => {
  // error tracker should have been sent when error encountered (in catchError)
  if (!tracker.error) {
    // here we just calculate processing time and send
    tracker.processingLength = Date.now() - tracker.requestLength - tracker.start;
    // dispatch tracker here
    store.dispatch(sendRequestTracker(tracker));
  }
};

export const trackRequestError = (tracker: RequestTracker, store: Store, e: Error, onError?: (tracker: RequestTracker, data?: any) => Observable<any>) => {
  // treat any data reading errors here
  // make error object serializable
  const errSerializable = asSerializable(e);
  tracker.error = errSerializable;
  tracker.processingLength = 0;
  // dispatch tracker here
  store.dispatch(sendRequestTracker(tracker));
  // handle errors
  return onError ? onError(tracker) : NEVER;
};
/* use this to enable tracking API calls via analytics */
export const trackApiRequest = <T>(
  tracker: RequestTracker, store: Store,
  onError?: (t: RequestTracker, data?: any) => Observable<any>
): (source$: Observable<T>) => Observable<T> => {
  // returns operator
  return (source$) => source$.pipe(
    tap(() => {
      // request returns, mark down the time
      tracker.requestLength = Date.now() - tracker.start;
    }
    ),
    // error handling sends tracker with error
    catchError((e) => trackRequestError(tracker, store, e, onError)),
    // else send tracker on finalize()
    finalize(() => finallyIfSuccesSendTracker(tracker, store))
  );
};

