
import * as global from './actions';
import { deleteDatasetItemInState, mergeInState, replaceInState, replaceKeyValuePairInStateArray } from '../lib';
import { Point } from '@angular/cdk/drag-drop';
import { isPointIn } from '@app/utils';
import { Rect } from '@app/shared';
import { environment } from '@e/environment';
import { BREAKPOINT, LogLevel, NAME, RoutingInfo, SECTION, TraceLog, TraceSettings } from '../types';
const MEGA_MENU = [SECTION.DATA, NAME.MENU];
const BREADCRUMBS = [SECTION.DATA, NAME.BREADCRUMBS];
export interface State {
  language: string;
  loadingAuthenticationStatus: boolean;
  loadingApplication: boolean;
  pageLoaded: boolean;
  preferencesLoaded: boolean;
  authenticated: boolean;
  authenticated2FA: boolean;
  dashboardStateConfig: string[];
  username: string;
  accountName: string;
  variables: Record<string, string | number | boolean>;
  lastClick: Point;
  windowRect: Rect;
  windowScrollRect: Rect;
  breakPoint: BREAKPOINT;
  trace: TraceSettings;
  logLevel: LogLevel;
  traceLog: TraceLog[];
  routingInfo?: RoutingInfo;
  lockDeactivate: boolean; // do not allow leave current route
  data: { [id: string]: any };
  lastActionUNID: string; // record once action using UNID completed
  currentPage: { url: string, code?: string };
}
export const StateInitial: State = {
  language: environment.defaultLanguage,
  loadingAuthenticationStatus: true,
  loadingApplication: true,
  pageLoaded: false,
  authenticated: false,
  authenticated2FA: false,
  username: '',
  accountName: '',
  preferencesLoaded: false,
  dashboardStateConfig: [],
  variables: {} as Record<string, string | number | boolean>,
  breakPoint: BREAKPOINT.xl,
  trace: environment.trace as TraceSettings, // defaults defined in environment
  logLevel: environment.logLevel,
  data: {}
} as State;

export const getLanguage =
  (s: State) => s.language;
export const getLoadingAuthentication = (s: State) =>
  s.loadingAuthenticationStatus;
export const getLoading = (s: State) =>
  s.loadingAuthenticationStatus || s.loadingApplication;
export const getPageLoaded = (s: State) => s.pageLoaded;
export const getActionCompletedUNID = (s: State) => s.lastActionUNID;
export const getVariables = (s: State) => s.variables;
export const getAuthenticated = (s: State) => s.authenticated;
export const getAuthenticated2FA = (s: State) => s.authenticated2FA;
export const getPreferencesLoaded = (s: State) => s.preferencesLoaded;
export const getDashboardStateConfig = (s: State) => s.dashboardStateConfig;
export const getWindowRect = (s: State) => s.windowRect;
export const getWindowScrollRect = (s: State) => s.windowScrollRect;
export const getBreakpoint = (s: State) => s.breakPoint;
export const getUsername = (s: State) => s.username;
export const getAccountName = (s: State) => s.accountName;
export const getClickNotInElement = (s: State, el: HTMLElement) => {
  const isin = s.lastClick && isPointIn(s.lastClick, el);
  return !isin;
};
export const getTrace = (s: State) => s.trace;
export const getLogLevel = (s: State) => s.logLevel;
export const getRoutingLock = (s: State) => s.lockDeactivate;

export function reducer(
  state: State = StateInitial,
  action: global.ActionTypes
): State {
  switch (action.type) {
    case global.setLanguage.type: {
      return {
        ...state,
        language: action.language
      };
    }
    case global.setWindowRect.type: {
      return {
        ...state,
        windowRect: { ...action.rect }
      };
    }
    case global.setWindowScrollRect.type: {
      return {
        ...state,
        windowScrollRect: { ...action.rect }
      };
    }
    case global.setLoadingAuthStatus.type: {
      return {
        ...state,
        loadingAuthenticationStatus: action.status
      };
    }
    case global.setAuthenticated.type: {
      return {
        ...state,
        authenticated: action.status,
        // change 2FA if logout
        // authenticated2FA: action.status ? state.authenticated2FA: false,
        username: action.username,
        accountName: action.accountName
      };
    }
    case global.setAuthenticated2FA.type: {
      return {
        ...state,
        authenticated2FA: action.status,
        // do not change other state unless TRUE
        // authenticated: action.status ? true : state.authenticated,
        username: action.username,
        accountName: action.accountName
      };
    }
    case global.setLoadingStatus.type: {
      return {
        ...state,
        loadingApplication: action.status
      };
    }
    case global.setPageLoaded.type: {
      return {
        ...state,
        pageLoaded: action.status
      };
    }
    case global.setPreferencesLoaded.type: {
      return {
        ...state,
        preferencesLoaded: action.status
      };
    }
    case global.setDashboardStateConfig.type: {
      return {
        ...state,
        dashboardStateConfig: [...action.dashboardStateConfig]
      };
    }
    case global.setCurrentPage.type: {
      return {
        ...state,
        currentPage: action.page
      };
    }
    case global.setVariable.type: {
      return replaceInState(state, ['variables', action.name], action.value);
    }
    case global.setMouseClick.type: {
      return {
        ...state,
        lastClick: action.point
      };
    }
    case global.upsertRecordInGlobalDataSet.type: {
      return mergeInState(
        state,
        [SECTION.DATA, action.dataSetName],
        action.data
      );
    }
    case global.setGlobalDataSet.type: {
      return replaceInState(
        state,
        [SECTION.DATA, action.dataSetName],
        action.data
      );
    }
    case global.addMegaMenu.type: {
      return mergeInState(
        state,
        [...MEGA_MENU],
        action.data
      );
    }
    case global.addBreadcrumbs.type: {
      return mergeInState(
        state,
        [...BREADCRUMBS],
        action.data
      );
    }
    case global.upsertKeyValuePairInGlobalDataSet.type: {
      console.log('upsertKeyValuePairInGlobalDataSet', action.data);
      return replaceKeyValuePairInStateArray(
        state,
        [SECTION.DATA, action.dataSetName],
        action.data
      );
    }
    case global.deleteGlobalDataSetItem.type: {
      return deleteDatasetItemInState(
        state,
        [SECTION.DATA, action.dataSetName],
        action.keyName,
        action.key

      );
    }
    case global.setBreakpoint.type: {
      return {
        ...state,
        breakPoint: action.breakpoint
      };
    }
    case global.initTrace.type: {
      return {
        ...state,
        trace: {...action.trace }
      };
    }
    case global.setTrace.type: {
      return {
        ...state,
        trace: { ...state.trace, [action.trace]: action.value }
      };
    }
    case global.setNamedTrace.type: {
      return {
        ...state,
        trace: { ...state.trace, named: action.trace }
      };
    }
    case global.setLogLevel.type: {
      return {
        ...state,
        logLevel: action.level
      };
    }
    case global.toggleNamedTrace.type: {
      const named = state.trace?.named || [];
      if (action.on && !named.includes(action.trace)) {
        named.push(action.trace);
      } else if (!action.on && named.includes(action.trace)) {
        named.splice(named.indexOf(action.trace), 1);
      }
      return {
        ...state,
        trace: { ...state.trace, named }
      };
    }
    case global.traceLog.type: {
      // we do not store other parameters
      const { name, text, level } = action;
      return {
        ...state,
        traceLog: [...(state.traceLog || []), { level, name, text }]
      };
    }
    case global.startRouting.type: {
      if (state.routingInfo?.routingId) {
        console.warn('Routing, route #', state.routingInfo?.routingId, 'in process. Starting new route #', action.id);
      }
      // normally we drop the lock, but keep it if already set and we start popstate routing
      const lockDeactivate = action.trigger === 'popstate' && state.lockDeactivate;
      return {
        ...state,
        lockDeactivate,
        routingInfo: {
          routingId: action.id,
          trigger: action.trigger,
        }
      };
    }
    case global.stopRouting.type: {
      if (state.routingInfo?.routingId !== action.id) {
        console.warn('Routing, route #', state.routingInfo?.routingId, 'not finalized. Closing different route, #', action.id);
      }
      return {
        ...state,
        routingInfo: undefined
      };
    }
    case global.setPopState.type: {
      if (state.routingInfo) {
        console.warn('Routing, route #', state.routingInfo?.routingId, 'not finalized. Illegal Popstate');
      }
      return {
        ...state,
        lockDeactivate: !!state.routingInfo // set lock if routing in progress
      };
    }
    case global.setActionCompletedUNID.type: {
      return {
        ...state,
        lastActionUNID: action.unid
      };
    }
    default:
      return state;
  }
}
