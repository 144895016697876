import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[history-back]'
})
export class BackDirective {
  @HostListener('click')
  action(){
    history.back();
  }

}
