import { Component, OnInit } from '@angular/core';
import * as build from '@e/build';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setCurrentPage } from '@reducers/global/actions';
import { State } from '@reducers';

@Component({
  selector: 'page-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {
  build = build;
  constructor(private store: Store<State>, private router: Router) { }

  ngOnInit() {
    // save current page (triggers event to analytics)
    this.store.dispatch(setCurrentPage(this.router.url));
  }
  goHome() {
    this.router.navigate(['/']);
  }
}
