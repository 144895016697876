import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MultiTranslateLoaderService implements TranslateLoader {
  resources = environment.languageResources;

  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<any> {
    const requests = this.resources.map(resource => {
      return this.http
        .get<any>(`${resource.prefix}${lang}${resource.suffix}`)
        .pipe(
          map(o => {
            if (resource.namespace) {
              const t = {};
              t[resource.namespace] = o;
              return t;
            } else {
              return o;
            }
          }),
          catchError(err => of(undefined))
        );
    });
    return forkJoin(requests).pipe(
      map(arr =>
        arr.filter(t => t !== undefined).reduce((a, b) => {
          return { ...a, ...b };
        }, {})
      )
    );
  }
}
