import { Observable, Subject, from } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

const wPath = environment.webcomponentsPath;
const wCDNjs = environment.webcomponentsCdnJs;
const footerVersion = environment.footerVersion;
function addStyleLink(url: string) {
  const style = document.createElement('link');
  style.setAttribute('href', url);
  style.setAttribute('rel', 'stylesheet');
  document.getElementsByTagName('head')[0].appendChild(style);
}
function addScriptLink(src: string, async = false, type?: string, onload?: () => void, onerror?: (e: any) => void) {
  const script = Object.assign(document.createElement('script'), {
    src,
    async,
    defer: undefined,
    onload, onerror
  });
  if (type) {
    script.type = type;
  }
  document.head.appendChild(
    script
  );
}
export function loadScript(src: string, async = false, type?: string): Observable<void> {
  const subject = new Subject<void>();
  addScriptLink(src, async, type, () => {
    subject.next();
  }, (error: any) => {
    console.error('load failure', src, error);
    subject.next();
  });
  
  return subject.pipe(take(1));
}

export const loadNavbar: () => void = () => {
  // footer
  addScriptLink(
    `${wCDNjs}/${footerVersion}/components/wipo-footer.js`, true, 'module');
  addStyleLink(`${wCDNjs}/${footerVersion}/style/footer.css`);
  // navbar
  from(environment.navbarUlrs.map(url => `${wPath}${url}`))
    .pipe(mergeMap(url => loadScript(url, true)))
    .subscribe();
};
