import { PersistenceService } from '@core/persistence.service';
import { Injectable, RendererFactory2 } from '@angular/core';
import { of, from, NEVER, combineLatest, fromEvent, Observable } from 'rxjs';
import {
  tap,
  switchMap,
  catchError,
  mergeMap,
  withLatestFrom,
  map,
  take,
  skipWhile,
  first,
  debounceTime,
  exhaustMap
} from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import {
  State, languageSelector, STORE_PERSISTED,
  authenticatedSelector, authenticated2FASelector, authenticationLoadingSelector,
  preferencesLoadedSelector, pageLoadedSelector, megaMenuSelector, preferencesSelector, breadcrumbsSelector, traceSelector, logLevelSelector,
  createTracker, trackApiRequest, globalVariableSelector, breakpointSelector
} from './reducers';
import {
  setLanguage,
  initApplication,
  setAuthenticated,
  setLoadingAuthStatus,
  actionNOOP,
  actionLogin,
  setPreferencesLoaded,
  setLoadingStatus,
  actionLogin2FA,
  setAuthenticated2FA,
  setVariable,
  actionLogout,
  setCurrentPage,
  setPageLoaded,
  setWindowRect,
  setGlobalDataSet,
  loadMegaMenu,
  addMegaMenu,
  loadPreferences,
  upsertKeyValuePairInGlobalDataSet,
  loadBreadcrumbs,
  addBreadcrumbs,
  upsertRecordInGlobalDataSet,
  setBreakpoint,
  startRouting,
  stopRouting,
  setPopState,
  traceLog,
  savePreference,
  setActionCompletedUNID,
  upsertPersistKeyValuePairInGlobalDataSet,
  deletePersistGlobalDataSetItem,
  deleteGlobalDataSetItem,
  setWindowScrollRect,
  initTrace,
} from './reducers/global/actions';
import {
  clearWidgetDataSet,
  setWidgetDataSet,
  loadWidgetDataSet,
  loadAppendWidgetDataSet,
  upsertPersistWidgetDataSet,
  upsertWidgetDataSet,
  deletePersistWidgetDataSetItem,
  deleteWidgetDataSetItem,
  persistDeleteByKey,
  persistByKey,
  loadWidgetDataSetWithCache,
  setWidgetDataSetCachedUntil,
  clearAllWidgetDataSetCache,
  sendRequestTracker,
  setWidgetDataSetError,
  discardWidgetDataSetCache,
  loadWidgetDataAsText,
  setWidgetDataAsText,
  loadWidgetDataAsTextWithCache,
  resendAll,
  postWidgetDataSet,
  postWidgetData,
  setWidgetDataSetParams,
  appendWidgetDataSet,
  loadWidgetDataObject,
  setWidgetDataObject,
  setWidgetDateActionTypes,
  loadAppendWidgetDataObject,
  appendWidgetDataObject,
  clearWidgetDataSetError,
} from './reducers/widgets/actions';

import { WipoAuthService } from '@core/wipo-auth.service';
import { WidgetDataService } from '@core/widget-data.service';
import { Action, TypedAction } from '@ngrx/store/src/models';
import { StateInitial } from './reducers/global/reducer';
import { getWidgetDataSetCachedUntil, getWidgetDataSetParams } from './reducers/widgets/reducer';
import { copyDeep } from './utils';
import { Cookies } from './utils/cookies';
import { environment } from 'environments/environment';
import { VAR, CustomEvents, widgetRequires2FA, WIDGET, getMomentLocale, STYLE, PAGE } from './shared';
import { RequestTracker } from './model/request-tracker';
import { dispatchCustomEvent } from './utils/lib';
import { HttpClient } from '@angular/common/http';
import { KeyValuePair, ObjectLiteral, WidgetDataObject } from '@app/model/widget-data';
import {
  BREAKPOINT,
  DatasetTo, getBreakpoint, LogLevel, NAME, NamedWidgetDatasetFromItem, PageMarker,
  PAGES,
  SECTION,
  StatusContainer, TRACE, TraceSettings, WidgetDataset, WidgetDatasetFrom, WidgetDatasetTo
} from './reducers/types';
import { LocationStrategy } from '@angular/common';
import { deleteInState } from './reducers/lib';
import { parse } from 'yaml';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, Event } from '@angular/router';


@Injectable()
export class AppEffects {
  // remove data when persisting
  doNotPersistKeys: string[][] = environment.store.doNotPersistKeys;
  traceStore: boolean;
  traceEvents: boolean;
  constructor(
    public http: HttpClient,
    private actions$: Actions,
    private store: Store<State>,
    private dataService: WidgetDataService,
    private wipoAuth: WipoAuthService,
    private translate: TranslateService,
    private rendererFactory: RendererFactory2,
    private persistence: PersistenceService,
    private location: LocationStrategy,
    private router: Router,
  ) {
    this.store.select(traceSelector).subscribe(trace => {
      // tslint:disable-next-line: no-shadowed-variable
      const { store, events } = trace || { store: false, events: false };
      this.traceStore = store;
      this.traceEvents = events;
    });
  }

  initApp$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(initApplication),
    tap(() => this.init()),
    // finaly initialize language
    withLatestFrom(this.store.select(languageSelector)),
    switchMap((x: [any, string]) => {
      const langCookie = Cookies.get(environment.languageCookieName);
      const lang = langCookie && environment.availableLanguages.includes(langCookie) ? langCookie : x[1];
      return from([setLanguage(lang), setLoadingStatus(false)]);
    })
  ));

  loginWPwd$ = createEffect(() => this.actions$.pipe(
    ofType(actionLogin),
    tap(() => this.wipoAuth.loginWithUserPassword())
  ), { dispatch: false });

  loginW2FA$ = createEffect(() => this.actions$.pipe(
    ofType(actionLogin2FA),
    tap(() => this.wipoAuth.loginWith2FA())
  ), { dispatch: false });

  logOut$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(actionLogout),
    tap(() => this.wipoAuth.logout()),
    mergeMap(() => of(setAuthenticated(false)))
  ));

  doOnAuthenticated2FAStatusChange$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(setAuthenticated2FA),
    switchMap(a =>
      a.status ? of(setAuthenticated(a.status, a.username, a.accountName)) : NEVER // no action if no 2fa (not sure what state is basic autherntication)
    )
  ));

  doOnAuthenticatedStatusChange$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(setAuthenticated),
    // detect if UNICC preferences endpoint is available, else try to switch to AWS
    exhaustMap((s: StatusContainer) => s.status ?
      this.http.get<any>(`${environment.urlPersistanceUNICC}/ping-unicc-prefs`)
        .pipe(
          map(() => false),
          // if UNICC unavailable, fall-back to AWS
          catchError((err) => of(err.status === 404)),
          tap((aws: boolean) => this.persistence.useAWS = aws),
          map(() => s)
        ) : of(s)),
    tap(() => {
      const useAWS = Cookies.get('use_aws');
      if (useAWS) {
        // simulate switch (missing UNICC)
        this.persistence.useAWS = true;
      }
    }),
    mergeMap((a: StatusContainer) =>
      // if success, load prefs if status changed to TRUE
      a.status
        ? from([
          setLoadingAuthStatus(false),
          // if authenticated proceed to load persisted data (only once if find cached)
          loadPreferences()
        ])
        : // else clear preferences and widget data
        from([
          setAuthenticated2FA(false),
          setLoadingAuthStatus(false),
          setPreferencesLoaded(false),
          clearAllWidgetDataSetCache()
        ])
    )
  ));

  language$ = createEffect(() => this.actions$.pipe(
    ofType(setLanguage),
    tap(action => {
      if (!this.translate.defaultLang) {
        // default is always EN
        this.translate.setDefaultLang(StateInitial.language);
      }
      this.translate.use(action.language);
      // only chinese and arab need specific region (map in common.ts)
      moment.locale(getMomentLocale(action.language));
    })
  ), { dispatch: false });

  clearWidgetData$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(clearWidgetDataSet),
    mergeMap((action: WidgetDataset) =>
      of(setWidgetDataSet(action.widgetName, action.dataSetName, []))
    )
  ));

  reLoadWidgetData$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadWidgetDataSetWithCache),
    map((action) =>
      Object.values(action)
    ),
    withLatestFrom(this.store),
    mergeMap(
      ([
        [widgetName, dataSetName, url, cacheMs, discardCache],
        state
      ]: [[string, string, string, number, boolean], State]) => {
        const cachedUntil = getWidgetDataSetCachedUntil(
          state.widgets,
          widgetName,
          dataSetName, url
        );
        // load new data if url changed or data expired
        if (!discardCache && cachedUntil && (cachedUntil.url === url && cachedUntil.expiry > Date.now())) {
          // no action
          return from([]);
        } else {
          // else load and cache
          const cacheUntil = Date.now() + cacheMs;
          return from([
            loadWidgetDataSet(widgetName, dataSetName, url),
            setWidgetDataSetCachedUntil(widgetName, dataSetName, cacheUntil, url)
          ]);
        }
      }
    )
  ));

  reLoadWidgetDataAsText$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadWidgetDataAsTextWithCache),
    map((action) =>
      Object.values(action)
    ),
    withLatestFrom(this.store),
    mergeMap(
      ([
        [widgetName, dataSetName, url, cacheMs, discardCache],
        state
      ]: [[string, string, string, number, boolean], State]) => {
        const cachedUntil = getWidgetDataSetCachedUntil(
          state.widgets,
          widgetName,
          dataSetName, url
        );
        // load new data if url changed or data expired
        if (!discardCache && cachedUntil && (cachedUntil.url === url && cachedUntil.expiry > Date.now())) {
          // this actually triggers "send any data requested, but not yet sent"
          return of(resendAll());
        } else {
          const cacheUntil = Date.now() + cacheMs;
          return from([
            loadWidgetDataAsText(widgetName, dataSetName, url),
            setWidgetDataSetCachedUntil(widgetName, dataSetName, cacheUntil, url)
          ]);
        }
      }
    )
  ));

  reLoadPostWidgetData$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(postWidgetDataSet),
    map((action) =>
      Object.values(action)
    ),
    withLatestFrom(this.store),
    mergeMap(
      ([
        [widgetName, dataSetName, url, cacheMs, payLoad, discardCache],
        state
      ]: [[string, string, string, number, ObjectLiteral, boolean], State]) => {
        const cachedUntil = getWidgetDataSetParams(
          state.widgets,
          widgetName,
          dataSetName, url, payLoad
        );
        // load new data if input parameters are changed
        if (!discardCache && cachedUntil && cachedUntil.url === url && (cachedUntil.params === JSON.stringify(payLoad))) {
          // this actually triggers "send any data requested, but not yet sent"
          return of(resendAll());
        } else {
          const cacheUntil = Date.now() + cacheMs;
          return from([
            postWidgetData(widgetName, dataSetName, payLoad, url),
            setWidgetDataSetParams(widgetName, dataSetName, cacheUntil, url, payLoad)
          ]);
        }
      }
    )
  ));
  upsertPersistKeyValuePairInGlobalDataSet$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(upsertPersistKeyValuePairInGlobalDataSet),
    mergeMap(action => from(
      [
        upsertKeyValuePairInGlobalDataSet(SECTION.PERSISTENCE, action.data),
        persistByKey(action.data, action.dataSetName,
          action.url, '', 'text/plain')
      ]))));
  deletePersistGlobalDataSetItem$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(deletePersistGlobalDataSetItem),
    mergeMap(action => from(
      [
        deleteGlobalDataSetItem(SECTION.PERSISTENCE, action.keyName, action.key),
        persistDeleteByKey(action.url, action.key)
      ]))));
  savePreference$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(savePreference),
    mergeMap(action => from(
      [
        upsertKeyValuePairInGlobalDataSet(SECTION.PERSISTENCE, { key: action.name, value: action.value }),
        persistByKey({ key: action.name, value: action.value }, action.name,
          this.persistence.authenticated ? this.persistence.urlPrefs : undefined, action.unid, 'text/plain')
      ]))));
  loadPreferences$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadPreferences),
    mergeMap((action: { name?: string }) => {
      const prefsUrl = this.persistence.urlPrefs;
      const url = action.name ? `${prefsUrl}/${action.name}` : prefsUrl;
      const tracker = createTracker(PAGES.GLOBAL, SECTION.PERSISTENCE, url, 'GET');
      return this.dataService.getAll(url).pipe(
        trackApiRequest(tracker, this.store),
        mergeMap((data: KeyValuePair & KeyValuePair[]) => {
          this.store.dispatch(traceLog(LogLevel.TRACE, TRACE.STORE, `PREFS KEY, ${action.name}`, data));
          const actions = action.name ? [
            // set particular preferences entry
            upsertKeyValuePairInGlobalDataSet(SECTION.PERSISTENCE, data)
          ] : [
            setGlobalDataSet(SECTION.PERSISTENCE, data),
            setPreferencesLoaded(true)
          ] as TypedAction<any>[];
          return from(actions);
        })
      );
    })
  ));

  loadWidgetData$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadWidgetDataSet),
    this.loadAndTrackWidgetData(setWidgetDataSet, (action) => {
      return (action.widgetName === PAGES.GLOBAL && action.dataSetName === SECTION.PERSISTENCE) ? setPreferencesLoaded(true) : undefined;
    })
  ));
  loadWidgetDataObject$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadWidgetDataObject),
    this.loadAndTrackWidgetData(setWidgetDataObject)
  ));
  loadAppendWidgetDataObject$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadAppendWidgetDataObject),
    this.loadAndTrackWidgetData(appendWidgetDataObject)
  ));

  loadAppendWidgetData$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadAppendWidgetDataSet),
    this.loadAndTrackWidgetData(appendWidgetDataSet)
  ));
  loadWidgetDataAsText$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadWidgetDataAsText),
    this.loadAndTrackWidgetData(setWidgetDataAsText)
  ));

  postWidgetData$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(postWidgetData),
    mergeMap((action: WidgetDatasetTo) => {
      const tracker = createTracker(action.widgetName, action.dataSetName, action.url, 'POST', action.payLoad);
      return this.dataService.post(action.url, action.payLoad).pipe(
        trackApiRequest(tracker, this.store),
        mergeMap((data: string) => {
          const actions = [
            setWidgetDataAsText(action.widgetName, action.dataSetName, data)
          ] as TypedAction<any>[];
          return from(actions);
        })
      );
    }
    )
  ));

  // send page tracker
  sendPageTracker$ = createEffect(() => this.actions$.pipe(
    ofType(setCurrentPage),
    tap(action => {
      // wait until page has been loaded to send first event
      this.store.pipe(
        select(pageLoadedSelector),
        mergeMap(state => state ? of(action) : NEVER),
        first()
      ).subscribe((actionLoaded: PageMarker) => dispatchCustomEvent(CustomEvents.ROUTING_FINISHED,
        { value: 1, linkUrl: actionLoaded.page.url, linkCode: actionLoaded.page.code }));
    })
  ), { dispatch: false });

  // sendRequestTracker
  sendRequestTracker$ = createEffect(() => this.actions$.pipe(
    ofType(sendRequestTracker),
    tap(action => {
      const trackerEvent = new CustomEvent(CustomEvents.API_REQUEST, {
        detail: {
          tracker: copyDeep(action.tracker)
        }
      });
      document.dispatchEvent(trackerEvent);
    })
  ), { dispatch: false });

  persistByKey$ = createEffect(() => this.actions$.pipe(
    ofType(persistByKey),
    tap(action => console.log),
    mergeMap((action: DatasetTo) =>
      from(
        this.dataService.putAll(
          action.url,
          action.key,
          action.data,
          action?.contentType
        )
      ).pipe(
        tap(http => http.subscribe(() => {
          // when call returns and we have unid, update it
          if (action.unid) {
            this.store.dispatch(setActionCompletedUNID(action.unid));
          }
        })),
        catchError(e => {
          console.error(e);
          return of([]);
        })
      )
    )
  ), { dispatch: false });

  persistDeleteByKey$ = createEffect(() => this.actions$.pipe(
    ofType(persistDeleteByKey),
    mergeMap((action: {
      url: string,
      key: string
    }) =>
      // from will auto-subscribe
      from(this.dataService.delete(action.url, action.key)).pipe(
        catchError(e => {
          console.error(e);
          return of([]);
        })
      )
    )
  ), { dispatch: false });

  upsertPersistWidgetData$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(upsertPersistWidgetDataSet),
    mergeMap((action: (DatasetTo & WidgetDataset)) =>
      from([
        // skip persist if url not provided
        action.url
          ? persistByKey(
            action.data,
            action.key,
            action.url,
            undefined,
            action.contentType
          )
          : actionNOOP(),
        upsertWidgetDataSet(
          action.widgetName,
          action.dataSetName,
          action.data,
          action.key
        )
      ])
    )
  ));

  deletePersistWidgetDataItem$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(deletePersistWidgetDataSetItem),
    mergeMap((action: NamedWidgetDatasetFromItem) =>
      from([
        persistDeleteByKey(action.url, action.itemName),
        deleteWidgetDataSetItem(
          action.widgetName,
          action.dataSetName,
          action.keyName,
          action.itemName
        )
      ])
    )
  ));
  // load breadcrumbs
  loadBreadcrumbs$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadBreadcrumbs),
    withLatestFrom(
      this.store.select(languageSelector),
      this.store.select(breadcrumbsSelector)
    ),
    mergeMap(([action, lang, menu]) => {
      const defLang = environment.defaultLanguage;
      // if no menu for this language - try load
      return (menu && menu[lang]) ? NEVER : this.loadBreadcrumbsFor(lang).pipe(catchError(() => {
        // do not send error tracker, this is our app local resource that might not exist for some languages
        if (menu && menu[defLang]) {
          // return existing default menu
          return of(upsertRecordInGlobalDataSet(NAME.BREADCRUMBS, { [lang]: menu[defLang] }));
        } else {
          // load menu for default language (and one with the problem/missing)
          return this.loadBreadcrumbsFor(defLang, lang);
        }
      }));
    })
  )
  );
  // load landing page menu for current language (or default language if current is missing)
  loadLandingMenu$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(loadMegaMenu),
    // triggered loading of menu for the current language
    withLatestFrom(
      this.store.select(languageSelector), this.store.select(megaMenuSelector())),
    // let us check what we have right now
    mergeMap(([action, lang, menu]) => {
      const defLang = environment.defaultLanguage;
      // if no menu for this language - try load
      return (menu && menu[lang]) ? NEVER : this.loadMenuFor(lang).pipe(catchError(() => {
        // do not send error tracker, this is our app local resource that might not exist for some languages
        if (menu && menu[defLang]) {
          // return existing default menu
          return of(upsertRecordInGlobalDataSet(NAME.MENU, { [lang]: menu[defLang] }));
        } else {
          // load menu for default language (and one with the problem/missing)
          return this.loadMenuFor(defLang, lang);
        }
      }));
    })
  ));
  setWindowRect$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(setWindowRect),
    switchMap((action) => of(setBreakpoint(getBreakpoint(action.rect.width)))
    )));
  traceLog$ = createEffect((): Observable<Action> => this.actions$.pipe(
    ofType(traceLog),
    withLatestFrom(
      this.store.select(traceSelector),
      this.store.select(logLevelSelector),
    ),
    mergeMap(([entry, trace, level]) => {
      const show = !!(trace && (trace.named?.includes(entry?.name) || trace[entry?.name])) && level <= entry?.level;
      
      // try find source
      let sourceCode = '';
      Error.stackTraceLimit = 50; // actually we can get only 40 lines in Chrome
      const stack = new Error().stack.split('\n');
      // find our logger method
      const start = stack.findIndex((s, i) => s.indexOf('ConsoleLogger.record') > -1);
      stack.splice(0, start + 1);
      if (start > -1) {
        // see if we can find last method before first .log call
        const startCode = stack.findIndex(s => ['.log','.err','.warn'].every(x => s.indexOf(x) === -1));
        stack.splice(0, startCode);
        const source = stack[0];
        const link = source?.match(/\((.*)\)/);
        // got the link... gr8!
        if (startCode > -1 && link) {
          sourceCode = link[1];
        }
      }

      if (show) {
        const params = [`%c${entry.name}`,
        STYLE.LOG,
        entry.text, ...entry.optional, '\t', sourceCode];
        switch (entry.level) {
          case LogLevel.ERROR: {
            console.error(...params);
            break;
          }
          case LogLevel.WARN: {
            console.warn(...params);
            break;
          }
          case LogLevel.TRACE: {
            console.log(...params);
            break;
          }
          // the "no-console" rule referes to NodeJS console not browser console
          // tslint:disable-next-line: no-console
          default: console.debug(...params, sourceCode);
        }
      }
      return NEVER;
    })));

  private loadMenuFor(lang: string, lang2?: string): Observable<Action> {
    // data in local assets
    return this.loadYAMLFor(`${this.location.getBaseHref()}assets/i18n/landing-menu`, lang, addMegaMenu, lang2);
  }
  private loadBreadcrumbsFor(lang: string, lang2?: string): Observable<Action> {
    // data from navbar
    return this.loadYAMLFor(`${environment.webcomponentsPath}/wipo-navbar/assets/i18n/breadcrumbs`,
      lang, addBreadcrumbs, lang2);
  }
  private loadYAMLFor(name: string, lang: string, action: (data: any) => Action, lang2?: string): Observable<Action> {
    const url = `${name}/${lang}.yaml`;
    return this.dataService.getAny(url, { responseType: 'text' })
      .pipe(map((text) => {
        // parse YAML
        const menu = parse(text);
        // set if necessary the same data for 2 languages
        const data = lang2 ? { [lang]: menu, [lang2]: menu } : { [lang]: menu };
        return action(data);
      }));
  }
  require2FA(preferences: any) {
    const dashboard = preferences?.find((p: KeyValuePair) => p.key === 'dashboard-state');
    if (dashboard && dashboard.value && Array.isArray(dashboard.value.config)) {
      const configArr = dashboard.value.config as { widgetType: string }[];
      const require = configArr.some((c: { widgetType: string }) => widgetRequires2FA(c.widgetType as WIDGET));
      return require;
    } else { return false; }
  }
  init() {
    initDefaultTrace(this.store, environment.trace);
    this.store.dispatch(setLoadingStatus(true));
    this.store.select(logLevelSelector).subscribe(level => {
      this.store.dispatch(traceLog(LogLevel.TRACE, TRACE.STORE, `Log level: ${level} (${LogLevel[level]})`));
    });
    this.wipoAuth.initialize();
    if (environment.mockupData) {
      // phase out mockdata in PROD distribution
      console.warn(`Usage of "environment.mockupData" detected!

Phase out the usage of environment.mockupData to mock data in source code.
Instead run configuration "mock" to move "mock_data" folder content under assets/mockdata/
add urls to be mocked in "environments/environment.mock.ts" pointing to assets/mockdata/[widgetname]/[datasetname]
see environments/environment.mock.ts
      `);
    }
    // refresh loa (if user already authenticated, has ePCT widgets and 2fa = false)
    combineLatest([
      this.store.select(authenticationLoadingSelector),
      this.store.select(preferencesLoadedSelector),
      this.store.select(authenticatedSelector),
      this.store.select(authenticated2FASelector),
      this.store.select(preferencesSelector),
      this.store.select(globalVariableSelector(VAR.activePage))
    ]).pipe(
      map(([loading, prefsLoaded, a, a2FA, p, page]) => [loading, prefsLoaded, a, a2FA, this.require2FA(p) || page === PAGE.ALERTS]),
      tap(([loading, prefsLoaded, a, a2FA, require]) => {
        this.store.dispatch(traceLog(LogLevel.TRACE, TRACE.STORE,
          `AUTH:${a} 2FA:${a2FA}, PREFS:${prefsLoaded}, REQ:${require}, 
          REFRESH LOA:${!(loading || !prefsLoaded || !a || a2FA || !require)}`));
      }),
      skipWhile(([loading, prefsLoaded, a, a2FA, require]) =>
        loading || !prefsLoaded || !a || a2FA || !require),
      take(1)
    ).subscribe(() => {
      this.wipoAuth.refreshUserinfo();
    });

    const renderer = this.rendererFactory.createRenderer(null, null);
    // events with action
    renderer.listen(document, CustomEvents.LOGIN_CLICK, e => {
      this.store.dispatch(actionLogin());
    });
    renderer.listen(document, CustomEvents.MEGAMENU_TOGGLE, e => {
      this.store.dispatch(setVariable(VAR.showMegamenu, e.detail.value));
    });
    renderer.listen(document, CustomEvents.LOGOUT_CLICK, e => {
      this.store.dispatch(actionLogout());
    });
    // language change
    renderer.listen(document, CustomEvents.LANGUAGE_CHANGE, e => {
      const lang = e.detail.languageSelected;
      Cookies.set(environment.languageCookieName, lang);
      this.store.dispatch(setLanguage(lang));
    });
    // page loaded
    renderer.listen(window, 'load', () => {
      this.store.dispatch(setPageLoaded(true));
    });
    // reload bookmarks on bookmark status change from navbar
    renderer.listen(window, CustomEvents.APP_BOOKMARK_CLICK, () =>
      this.store.dispatch(loadPreferences(NAME.BOOKMARKS))
    );
    // debug loading
    this.store.select(pageLoadedSelector).subscribe((status: boolean) => {
      this.store.dispatch(traceLog(LogLevel.TRACE, TRACE.EVENTS, `store.pageLoaded: ${status}`));
    });
    // size+resize
    this.store.dispatch(setWindowRect({ width: window.innerWidth, height: window.innerHeight }));
    fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe(() => {
      this.store.dispatch(setWindowRect({ width: window.innerWidth, height: window.innerHeight }));
    });
    // scroll
    fromEvent(window, 'scroll').pipe(debounceTime(100)).subscribe(() => {
      this.store.dispatch(setWindowScrollRect({ width: window.scrollX, height: window.scrollY }));
    });

    // keep track of routing
    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationStart) {
        this.store.dispatch(traceLog(LogLevel.TRACE, TRACE.EVENTS, `ROUTING, start, ${e.constructor.name} ${e.id}`));
        this.store.dispatch(startRouting(e.id, e.navigationTrigger));
      } else if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
        this.store.dispatch(traceLog(LogLevel.TRACE, TRACE.EVENTS, `ROUTING, finished, ${e.constructor.name} ${e.id}`));
        this.store.dispatch(stopRouting(e.id));
      }
    });
    // watch for Popstate event
    window.addEventListener('popstate', e => {
      this.store.dispatch(setPopState());
    });
    // other events only log
    const EVENTS = [
      CustomEvents.LOGIN_CLICK,
      CustomEvents.LOGOUT_CLICK,
      CustomEvents.MEGAMENU_TOGGLE,
      CustomEvents.LANGUAGE_CHANGE,
      CustomEvents.WIDGET_ADD,
      CustomEvents.WIDGET_REMOVED,
      CustomEvents.WIDGET_DROPDOWN_OPTION_CLICKED,
      CustomEvents.WIDGET_DROPDOWN_TOGGLED,
      CustomEvents.TOGGLE_MENU,
      CustomEvents.CLEAR,
      CustomEvents.TEMPLATE,
      CustomEvents.WIDGET_CATEGORY,
      CustomEvents.ROUTING_FINISHED,
      CustomEvents.PORTAL_LINK_CLICK,
      CustomEvents.BUTTON_CLICK,
      CustomEvents.API_REQUEST,
      CustomEvents.FEEDBACK_SUBMITTED,
      CustomEvents.ACTION_CLICKED,
      CustomEvents.PROMO_LINK_CLICKED,
      CustomEvents.MESSAGE_CLICKED,
      CustomEvents.MESSAGE_SEARCHED,
      CustomEvents.MESSAGE_SELECTED,
      CustomEvents.WIDGET_BODY_CLICK,
      CustomEvents.IPPORTAL_UPDATE // this we send ourselves - make sure we send it
    ];
    EVENTS.forEach(event => {
      renderer.listen(document, event, (e: CustomEvent) => {
        this.trace(`Event received : ${e.type} ${JSON.stringify(e.detail)}`,);
      });
    });


    this.store.subscribe(s => {
      const state = this.doNotPersistKeys.reduce((tmpState, keys) => {
        return deleteInState(tmpState, [...keys]);
      }, s);
      sessionStorage.setItem(STORE_PERSISTED, JSON.stringify(state));
    });
  }
  trace(text: string, ...args: any) {
    this.store.dispatch(traceLog(LogLevel.TRACE, TRACE.EVENTS, text, ...args));
  }
  /* 
    load data with tracking from url and execute data update action once loaded 
  */
  loadAndTrackWidgetData(
    storeAction: setWidgetDateActionTypes,
    additionalAction?: (action: WidgetDatasetFrom) => any
  ): (source$: Observable<WidgetDatasetFrom>) => Observable<any> {
    return (source$) => source$.pipe(
      mergeMap((action: WidgetDatasetFrom) => {
        const tracker = createTracker(action.widgetName, action.dataSetName, action.url, 'GET');
        const httpRequest = this.dataService.getAll(action.url);
        return httpRequest.pipe(
          trackApiRequest(tracker, this.store, (t: RequestTracker) => {
            // on error store error and clear existing data
            this.store.dispatch(setWidgetDataSetError(action.widgetName, action.dataSetName, t.error));
            this.store.dispatch(discardWidgetDataSetCache(action.widgetName, action.dataSetName));
            return NEVER;
          }),
          mergeMap((dataIn: WidgetDataObject) => {
            const data = action.transform ? action.transform(dataIn) : dataIn;
            const nextActions = [
              // clear error
              clearWidgetDataSetError(action.widgetName, action.dataSetName),
              // send default action
              storeAction(action.widgetName, action.dataSetName, data)
            ];
            if (additionalAction) {
              const a = additionalAction(action);
              if (a) { nextActions.push(a); }
            }
            // chain action
            return nextActions;
          })
        );
      }
      ));
  }
}
export const initDefaultTrace = (store: Store, trace: TraceSettings) => {
  const traceWDefault = { ...trace, named: [...(trace?.named || []), '-', 'ipportal'] };
  store.dispatch(initTrace(traceWDefault));
}
// this is just an example; we use default mobile mode detection from w-angular
export const watchMobile = (store: Store) => ({
  mobile: () => store.select(breakpointSelector).pipe(map(b => b === BREAKPOINT.md))
});
