export class Helper {
    public static chunkInPages(list: any[], pageSize: number): any[][] {
        return list.reduce(
          (arr: any[][], item: any) => {
            if (arr.length === 0 || arr[arr.length - 1].length > pageSize-1) {
              // new page (either empty array or page is full)
              arr.push([]);
            }
            arr[arr.length - 1].push(item);
            return arr;
          },
          []
        );
      }
}
