import * as pages from './reducer';
import {
    createFeatureSelector, createSelector,
} from '@ngrx/store';
export const pagesStateSelector = createFeatureSelector<pages.State>(
    'pages'
);
export const landingPageSelector = createSelector(
    pagesStateSelector,
    pages.getLanding
);
export const landingPageVariableSelector = (name: string) => createSelector(
    landingPageSelector,
    (landing) => landing?.variables?.[name]
);
export const landingPageDataSelector = (name: string) => createSelector(
    landingPageSelector,
    (landing) => landing?.data?.[name]
);
