import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BackDirective} from './back.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { CrossEnvironmentDirective } from './cross-environment.directive';

const DIR = [CrossEnvironmentDirective, BackDirective, ClickOutsideDirective];
@NgModule({
  declarations: DIR,
  imports: [
    CommonModule
  ],
  exports: DIR
})
export class DirectivesModule { }
