import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
/* this is simplified version from library WRxBase class */
@Directive()
export abstract class Destructor implements OnDestroy {
    protected readonly destruction = new Subject<void>();
    ngOnDestroy() {
        this.destruction.next();
        this.destruction.complete();
      }
}
