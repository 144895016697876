import { KeyValuePair } from "app/model/widget-data";

export function replaceInState(
  stateIn: any,
  keys: string[],
  value: any | any[]
) {
  const state = { ...stateIn };
  const key = keys.shift();
  if (keys.length > 0) {
    const data = { ...state[key] } || {};
    state[key] = replaceInState(data, keys, value);
  } else {
    state[key] = value;
  }
  return state;
}
export function mergeInState(
  stateIn: any,
  keys: string[],
  value: any,
  transform?: (source, data)=>any
) {
  const state = { ...stateIn };
  const key = keys.shift();
  const stateKey = state[key];
  const data = { ...stateKey } || {};
  if (keys.length > 0) {
    state[key] = mergeInState(data, keys, value, transform);
  } else {
    if (transform){
      state[key] = transform(stateKey, value);
    } else     if (Array.isArray(stateKey) && Array.isArray(value)) {
      state[key] = [...stateKey, ...value];
    } else {
      state[key] = {...stateKey, ...value };
    }
  }
  return state;
}
export function replaceAsArrayInState(
  stateIn: any,
  keys: string[],
  value: any | any[]
) {
  const state = { ...stateIn };
  const key = keys.shift();
  if (keys.length > 0) {
    const data = { ...state[key] } || {};
    state[key] = replaceAsArrayInState(data, keys, value);
  } else {
    state[key] = Array.isArray(value) ? value : [value];
  }
  return state;
}
export function refreshArrayInState(
  stateIn: any,
  keys: string[]
) {
  const state = { ...stateIn };
  const key = keys.shift();
  if (keys.length > 0) {
    const data = { ...state[key] } || {};
    state[key] = refreshArrayInState(data, keys);
  } else if (Array.isArray(state[key])) {
    state[key] = [...state[key]];
  }
  return state;
}
export function deleteInState(
  stateIn: any,
  keys: string[],
) {
  const state = { ...stateIn };
  const key = keys.shift();
  if (keys.length > 0) {
    const data = { ...state[key] } || {};
    state[key] = deleteInState(data, keys);
  } else {
    delete state[key];
  }
  return state;
}
export function deleteDatasetItemInState(
  stateIn: any,
  keys: string[],
  itemKey: string,
  itemKeyValue: string
) {
  const state = { ...stateIn };
  const key = keys.shift();
  if (keys.length > 0) {
    const data = { ...state[key] } || {};
    state[key] = deleteDatasetItemInState(data, keys, itemKey, itemKeyValue);
  } else if (Array.isArray(state[key])) {
    state[key] = state[key].filter(item => item[itemKey] !== itemKeyValue);
  }
  return state;
}
export function replaceInStateArrayByKey(
  stateIn: any,
  keys: string[],
  id: string,
  value: any | any[]
) {
  const state = { ...stateIn };
  const key = keys.shift();
  if (keys.length > 0) {
    // not the last key, continue drill-down...
    const data = { ...state[key] } || {};
    state[key] = replaceInStateArrayByKey(data, keys, id, value);
  } else if (Array.isArray(state[key])) {
    if (Array.isArray(value)) {
      const newArr = state[key].map(v => v);
      // replace one by one
      value.forEach(v => {
        const old = state[key].find(o => o[id] === v[id]);
        if (old) {
          const idx = newArr.indexOf(old);
          console.log(newArr.length, 'update', idx, v[id], old, v);
          newArr[idx] = v;
        } else {
          console.log('add', v[id], v);
          newArr.push(v);
        }
      });
      state[key] = newArr;
    } else {
      state[key] = value;
    }
  } else {
    state[key] = value;
  }
  return state;
}
export function replaceKeyValuePairInStateArray(stateIn: any,
  keys: string[],
  value: KeyValuePair
) {
  const state = { ...stateIn };
  
  const key = keys.shift();
  if (keys.length > 0) {
    // not the last key, continue drill-down...
    const data = { ...state[key] } || {};
    state[key] = replaceKeyValuePairInStateArray(data, keys, value);
  } else if (Array.isArray(state[key])) {
    // this should an array be key-value pair
    const valueArr = state[key];
    const idx = valueArr.findIndex(v => v.key === value.key);
    if (idx > -1) {
      // replace @ index
      state[key] = valueArr.map((v, i) => i === idx ? { ...value } : { ...v });
    } else {
      // add a new item
      state[key] = [...valueArr, value];
    }
  } else {
    // we should not be here. just replace!
    state[key] = [value];
  }
  return state;

}
export function appendToArrayInState(
  stateIn: any,
  keys: string[],
  value: any[]
) {
  const state = { ...stateIn };
  const key = keys.shift();
  if (keys.length > 0) {
    const data = { ...state[key] } || {};
    state[key] = appendToArrayInState(data, keys, value);
  } else if (Array.isArray(state[key])) {
    state[key] = state[key].concat(value);
    console.log('DATA (concat)', key, state);
  } else if (state[key] === undefined) {
    state[key] = Array.isArray(value) ? value : [value];
    console.log('DATA (new)', key, state);
  } else {
    console.error(key, `failed to append`, state[key], `not an array`, Array.isArray(state[key]));
  }
  return state;
}
