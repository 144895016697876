import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  State,
  authenticatedSelector,
  preferencesLoadedSelector,
  preferencesSelector,
  preferencesChangedSelector
} from '@reducers';
import { tap, map, take, switchMap } from 'rxjs/operators';
import {
  upsertPersistWidgetDataSet,
  deletePersistWidgetDataSetItem,
  clearWidgetData,
  persistByKey,
  persistDeleteByKey
} from '@reducers/widgets/actions';
import { WidgetDataObject, castAs } from '@app/model/widget-data';
import { environment } from 'environments/environment';
import { Observable, of, NEVER } from 'rxjs';
import { copyDeep } from '@app/utils';
import { PAGES, SECTION } from '@reducers/types';
import { deleteGlobalDataSetItem, deletePersistGlobalDataSetItem, upsertKeyValuePairInGlobalDataSet, upsertPersistKeyValuePairInGlobalDataSet } from '@reducers/global/actions';
import { copyDeepPlain } from '@app/utils/lib';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {
  useAWS: boolean;
  get urlPrefs(): string {
    return this.useAWS ? environment.urlPersistanceAWS : environment.urlPersistanceUNICC;
  }

  data: { key: string; value: string }[];
  authenticated: boolean;
  dataLoaded: Observable<any>;

  constructor(public store: Store<State>) {
    // watch for persisted data
    this.dataLoaded = this.store.pipe(
      select(preferencesLoadedSelector),
      // let it pass only if prefs loaded
      switchMap((status) => status ? of(status) : NEVER),
      take(1)
    );
    this.store.pipe(
      select(preferencesSelector)
    ).subscribe((data: any) => {
      this.data = data;
    });
    // authentication status
    this.store
      .pipe(
        select(authenticatedSelector),
        tap(status => {
          this.authenticated = status;
        })
      )
      .subscribe();
  }
  private key(path: string, name: string) {
    return `${path}-${name}`;
  }
  clearDataFor(keyPrefix: string) {
    // preferences (and persist)
    this.data.forEach(d => {
      if (d.key?.startsWith(keyPrefix)) {
        this.deleteVariableByKey(d.key);
      } else if (!d.key){
        console.warn('PREFS, CLEAR, MISSING KEY', keyPrefix, d);
      }
    });

    // widget datasets, caches and variables
    this.store.dispatch(clearWidgetData(keyPrefix));
  }

  private deleteVariableByKey(key: string) {
    this.store.dispatch(
      deletePersistGlobalDataSetItem(SECTION.PERSISTENCE, 'key', key, this.urlPrefs)
      // deletePersistWidgetDataSetItem(
      //   PAGES.GLOBAL,
      //   SECTION.PERSISTENCE,
      //   'key',
      //   key,
      //   this.urlPrefs
      // )
    );
    if (!this.useAWS) {
      // we still use UNCC, shadow preferences on AWS
      this.store.dispatch(persistDeleteByKey(environment.urlPersistanceAWS, key));
    }
  }

  public deleteVariable(path: string, name: string) {
    // preferences on current environment
    this.deleteVariableByKey(this.key(path, name));
  }

  public setVariable(path: string, name: string, value: any) {
    const data = {
      key: this.key(path, name),
      value: copyDeepPlain(value), // this omit class prototype, hence can be serialized
    };
    this.store.dispatch(
      // insert and persist (new style)
      upsertPersistKeyValuePairInGlobalDataSet(SECTION.PERSISTENCE, data,  this.authenticated ? this.urlPrefs : undefined
      )
    );
    if (this.authenticated && !this.useAWS) {
      // we still use UNCC, shadow preferences on AWS
      this.store.dispatch(persistByKey(data, 'key', environment.urlPersistanceAWS));
    }
  }

  
  public getVariable(
    path: string,
    name: string,
    defaultValue?: any,
    asType?: any
  ) {
    const key = this.key(path, name);
    const o = this.data ? this.data.find(v => v.key === key) : undefined;
    return (o === undefined || o.value === undefined) ? defaultValue : copyDeep<any>(o.value, asType);
  }
  public watchVariable<T>(
    path: string,
    name: string,
    asType?: any
  ): Observable<T> {
    const key = this.key(path, name);
    return this.store.pipe(
      select(preferencesChangedSelector(key)),
      map((v: { key: string; value: T }) =>
        v ? copyDeep<T>(v.value, asType) : v
      )
    );
  }
  /* historical/deprecated */
  /**
 * @deprecated use setVariable
 */
  public setVariableAsObject(path: string, name: string, value: object) {
    this.setVariable(path, name, JSON.stringify(value));
  }
  /**
 * @deprecated use getVariable
 */
  public getVariableAsObject(path: string, name: string, defaultValue = null) {
    const result = this.getVariable(path, name);
    return result ? JSON.parse(result) : defaultValue;
  }
  /**
 * @deprecated use getVariable
 */
  public getVariableAsString(path: string, name: string, defaultValue = '') {
    const result = this.getVariable(path, name);
    return result ? result : defaultValue;
  }
  /**
 * @deprecated use setVariable
 */
  public setVariableAsString(path: string, name: string, value: string) {
    this.setVariable(path, name, value);
  }

  /**
   * @deprecated use getVariable
   */
  public getVariableAsBoolean(
    path: string,
    name: string,
    defaultValue = false
  ) {
    const result = this.getVariable(path, name);
    return result !== undefined ? result : defaultValue;
  }

  /**
   * @deprecated use setVariable
   */
  public setVariableAsBoolean(path: string, name: string, value: boolean) {
    this.setVariable(path, name, value);
  }
  /* local */
  public setLocalVariable(path: string, name: string, value: any) {
    window.localStorage.setItem(this.key(path, name), JSON.stringify(value));
  }
  public getLocalVariable(path: string, name: string, defaultValue: any) {
    const item = window.localStorage.getItem(this.key(path, name));
    return item !== undefined ? JSON.parse(item) : defaultValue;
  }
}
