import { createAction, union } from '@ngrx/store';
import { Point } from '@angular/cdk/drag-drop';
import { Rect } from '@app/shared';
import {
  BREAKPOINT,
  BreakpointContainer,
  DashboardStateConfig, DragLocation, LanguageContainer,
  LogLevel,
  NameValue, PageMarker, Rectangle,
  StatusContainer, UserStatus
} from '../types';
import { KeyValuePair } from '@app/model/widget-data';

export const actionNOOP = createAction('NOOP');
export const initApplication = createAction('init app');
export const actionLogin = createAction('login w password');
export const actionLogin2FA = createAction('login w 2-factor authentication');
export const setLanguage = createAction('switch language', (language: string): LanguageContainer => ({
  language
}));
export const actionLogout = createAction('logout');
export const setLoadingStatus = createAction('loading status', (status: boolean): StatusContainer => ({
  status
}));
export const setPageLoaded = createAction('page loaded status', (status: boolean): StatusContainer => ({
  status
}));
export const setBreakpoint = createAction('set width breakpoint', (breakpoint: BREAKPOINT): BreakpointContainer => ({
  breakpoint
}));
export const setMouseClick = createAction('set click', (event: MouseEvent): DragLocation => ({
  point: { x: event.clientX, y: event.clientY } as Point
}));
export const setLoadingAuthStatus = createAction('loading authentication status', (status: boolean): StatusContainer => ({
  status
}));
export const setActionCompletedUNID = createAction('set completed action UNID', (unid: string): any => ({
  unid
}));
export const setAuthenticated = createAction('set authenticated',
  (status: boolean, username: string = '', accountName: string = ''): UserStatus => ({
    status,
    username,
    accountName
  }));
export const setAuthenticated2FA = createAction('set authenticated 2FA',
  (status: boolean, username: string = '', accountName: string = ''): UserStatus => ({
    status,
    username,
    accountName
  }));
export const setPreferencesLoaded = createAction('set preferences loaded',
  (status: boolean): StatusContainer => ({
    status
  }));
export const setWindowRect = createAction('set window rectangle', (rect: Rect): Rectangle => ({
  rect
}));
export const setWindowScrollRect = createAction('set window scroll rectangle', (rect: Rect): Rectangle => ({
  rect
}));
export const setDashboardStateConfig = createAction('set dashboard state', (dashboardStateConfig: string[]): DashboardStateConfig => ({
  dashboardStateConfig
}));
export const setVariable = createAction('set variable', (name: string, value: any): NameValue => ({
  name, value
}));
export const setCurrentPage = createAction('set page',
  (url: string): PageMarker => ({ page: { url: document.location.href, code: url.substr(1) } }));
export const setGlobalDataSet = createAction(
  'set global dataset',
  (dataSetName: string, data: any) => ({
    dataSetName,
    data
  })
);
export const loadMegaMenu = createAction('load mega-menu');
export const loadBreadcrumbs = createAction('load breadcrumbs');
export const addMegaMenu = createAction(
  'add landing menu',
  (data: any) => ({ data })
);
export const addBreadcrumbs = createAction(
  'add breadcrumbs menu',
  (data: any) => ({ data })
);
export const loadPreferences = createAction(
  'load preferences',
  (name?: string) => ({ name })
);
export const savePreference = createAction(
  'save preference',
  (name: string, value?: any, unid?: string) => ({ name, value, unid })
);
export const upsertKeyValuePairInGlobalDataSet = createAction(
  'upsert key-value pair in global dataset',
  (
    dataSetName: string,
    data: KeyValuePair
  ) => ({
    dataSetName,
    data
  })
);
export const upsertPersistKeyValuePairInGlobalDataSet = createAction(
  'upsert key-value pair in global dataset and persist',
  (
    dataSetName: string,
    data: KeyValuePair,
    url?: string
  ) => ({
    dataSetName,
    data,
    url
  })
);
export const deleteGlobalDataSetItem = createAction(
  'delete global dataset item',
  (
    dataSetName: string,
    keyName: string,
    key: string
  ) => ({
    dataSetName,
    keyName,
    key
  })
);
export const deletePersistGlobalDataSetItem = createAction(
  'delete global dataset item and persist change',
  (
    dataSetName: string,
    keyName: string,
    key: string, url: string
  ) => ({
    dataSetName,
    keyName,
    key, url
  })
);
export const upsertRecordInGlobalDataSet = createAction(
  'upsert record in global dataset',
  (
    dataSetName: string,
    data: Record<string, any>
  ) => ({
    dataSetName,
    data
  })
);
export const initTrace = createAction('init trace', (trace: Record<string,any>) => ({ trace }));
export const setTrace = createAction('set trace', (trace: string, value: boolean) => ({ trace, value }));
export const setNamedTrace = createAction('set named trace', (trace: string[]) => ({ trace }));
export const toggleNamedTrace = createAction('toggle named trace', (trace: string, on: boolean) => ({ trace, on }));
export const traceLog = createAction('log trace', (level: LogLevel, name: string, text: string, ...optional) =>
  ({ level, name, text, optional }));
export const setLogLevel = createAction('set log level', (level: LogLevel) => ({ level }));

export const startRouting = createAction('start routing', (id: number, trigger: string) => ({ id, trigger }));
export const stopRouting = createAction('stop routing', (id: number) => ({ id }));
export const setPopState = createAction('set pop state');
export const actions = union({
  actionNOOP,
  setActionCompletedUNID,
  initApplication,
  addBreadcrumbs,
  addMegaMenu,
  actionLogin,
  actionLogin2FA,
  actionLogout,
  deleteGlobalDataSetItem,
  deletePersistGlobalDataSetItem,
  loadBreadcrumbs,
  loadMegaMenu,
  loadPreferences,
  savePreference,
  setBreakpoint,
  setGlobalDataSet,
  setLanguage,
  setLoadingStatus,
  setPageLoaded,
  setLoadingAuthStatus,
  setAuthenticated,
  setAuthenticated2FA,
  setLogLevel,
  setPreferencesLoaded,
  setDashboardStateConfig,
  setVariable,
  setMouseClick,
  setCurrentPage,
  setNamedTrace,
  initTrace,
  setTrace,
  setWindowRect,
  setWindowScrollRect,
  startRouting, stopRouting, setPopState,
  toggleNamedTrace,
  traceLog,
  upsertKeyValuePairInGlobalDataSet,
  upsertPersistKeyValuePairInGlobalDataSet,
  upsertRecordInGlobalDataSet
});
export type ActionTypes = typeof actions;
