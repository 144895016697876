import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, NEVER, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@reducers/global/reducer';
import {
  authenticatedSelector,
  authenticationLoadingSelector,
} from '@reducers';
import { take, map, tap, mergeMap } from 'rxjs/operators';
import { ROUTE } from '@shared/routing';
import { TRACE } from '@reducers/types';
import { console } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardOidc {
  instanceId = `prot-${Math.ceil(Math.random() * 100000)}`;
  url: string;
  constructor(private router: Router, private store: Store<State>) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const self = this.selfWOFragment(state.url);
    this.url = state.url;
    console.logAs(TRACE.ROUTING, `guard ${this.instanceId} PROTECTED: ${state.url} -> ${self}`);
    return this.entryRoute(self);
  }
  selfWOFragment(url: string): boolean | UrlTree {
    const idx1 = url.indexOf('#');
    const idx2 = url.indexOf('?');

    const idx = idx1 > -1 ? idx1 : idx2;
    return idx > -1 ? this.router.parseUrl(url.substr(0, idx)) : true;
  }

  entryRoute(self: boolean | UrlTree): Observable<UrlTree | boolean> {
    const authLoading = this.store.select(authenticationLoadingSelector);
    const authStatus = this.store.select(authenticatedSelector);

    // deals with protected urls
    return combineLatest([authStatus, authLoading]).pipe(
      tap(([auth, loading]) =>
        console.logAs(TRACE.ROUTING, `guard ${this.instanceId} PROTECTED auth state: ${auth}, loading: ${loading}`)
      ),
      mergeMap(([auth, loading]) => (loading ? NEVER : of(auth))),
      tap(state => console.logAs(TRACE.ROUTING, `guard ${this.instanceId} PROTECTED auth state: ${state}`)),
      take(1),
      map(authenticated => {
        // redirect to home if not authenticated
        console.logAs(TRACE.ROUTING, `guard ${this.instanceId} PROTECTED auth state, before redirect: ${authenticated}`);
        const route = authenticated ? self : this.router.parseUrl(ROUTE.ROOT);
        console.warnAs(TRACE.ROUTING,
          `guard ${this.instanceId} ${this.url} PROTECTED authenticated: ${authenticated}, ${route ? 'ALLOW' : 'REDIRECT'}`,
          route);
        return route;
      })
    );
  }
}
