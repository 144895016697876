/**
 * Special Storage-Implementation that uses sessionStorage (like the default of the
 * OAuth-library) but also sets a marker in localStorage so that other Browser-Tabs
 * get notified when the user on this Tab logs out.
 */

import { OAuthStorage } from 'angular-oauth2-oidc';

export class CrossTabStorage extends OAuthStorage {

  readonly MARKER_KEY = "IPP.UserIsAuth";

  constructor() {
    super();
    // We listen to changes on localstorage (the listener will only be fired if
    // another Browser-Tab makes changes)
    window.addEventListener("storage", (event: StorageEvent) => {
      if (event.key === this.MARKER_KEY) {
        // Another Browser-Tab removed the magic entry in localStorage (he logged out)
        // so we remove our tokens as well
        sessionStorage.clear();
        window.location.reload();
      }
    });
  }

  getItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
    if (key === "access_token") {
      // User has logged out on this Browser-Tab
      // Remove the magic entry on localStorage to tell other Browser-Tabs that they should
      // clear their session storage
      localStorage.removeItem(this.MARKER_KEY);
    }
  }

  setItem(key: string, data: string): void {
    sessionStorage.setItem(key, data);
    if (key === "access_token") {
      // User has logged in on this Browser-Tab
      // Set the magic entry that, when removed, fires events on other Browser-Tabs
      localStorage.setItem(this.MARKER_KEY, "true");
    }
  }
}