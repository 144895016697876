import { WaitResolver } from '../_resolvers/wait.resolver';

export const resolve = { wait: WaitResolver };
export const paths = {
    root: '',
    none: '**',
};
export enum ROUTE {
    ROOT = '',
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    HOME = 'home',
    LANDING = 'landing', // new home
    BUILD = 'build',
    LOGOUT = 'logout',
    ALERTS = 'alerts',
    ABOUT = 'about',
    FAQ = 'faq'
};
