import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { OAuthStorage, OAuthResourceServerErrorHandler, OAuthModuleConfig } from 'angular-oauth2-oidc';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {
  }

  private checkUrl(url: string): boolean {
    const found = this.moduleConfig.resourceServer?.allowedUrls?.find(u => url.startsWith(u));
    return !!found;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // how do we distingish which requests need credentials(?)
    const isAnonymous = environment.ANONYMOUS_URLS.some(url => req.url.includes(url));
    if (!isAnonymous) {
      req = req.clone({
        withCredentials: true
      });
    }
    if (req.responseType === 'json') {
      // TODO: Check with Wipo Pearl
      if (req.url.indexOf('https://www5.wipo.int/gipp-wipopearl2') > 0) {
        req = req.clone({
          setHeaders: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        });
      }
    }

    /**
     * add Bearer token
     */
    if (!isAnonymous && this.moduleConfig.resourceServer?.sendAccessToken && this.checkUrl(req.url.toLowerCase())) {
      const token = this.authStorage.getItem('access_token');
      if(token) {
        const headers = req.headers
        .set('Authorization', `Bearer ${token}`);
      req = req.clone({ headers });
      }
    }
    return next.handle(req);
  }
}
