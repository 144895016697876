import * as global from './reducer';
import {
  createFeatureSelector, createSelector,
} from '@ngrx/store';
import { KeyValuePair } from '@app/model/widget-data';
import { NAME, SECTION } from '../types';
export const globalStateSelector = createFeatureSelector<global.State>(
  'global'
);

export const languageSelector = createSelector(
  globalStateSelector,
  global.getLanguage
);
export const loadingSelector = createSelector(
  globalStateSelector,
  global.getLoading
);
export const pageLoadedSelector = createSelector(
  globalStateSelector,
  global.getPageLoaded
);
export const authenticatedSelector = createSelector(
  globalStateSelector,
  global.getAuthenticated
);
export const authenticated2FASelector = createSelector(
  globalStateSelector,
  global.getAuthenticated2FA
);
export const authenticationLoadingSelector = createSelector(
  globalStateSelector,
  global.getLoadingAuthentication
);
export const dashboardStateConfigSelector = createSelector(
  globalStateSelector,
  global.getDashboardStateConfig
);
export const windowRectSelector = createSelector(
  globalStateSelector,
  global.getWindowRect
);
export const windowScrollRectSelector = createSelector(
  globalStateSelector,
  global.getWindowScrollRect
);
export const breakpointSelector = createSelector(
  globalStateSelector,
  global.getBreakpoint
);
export const preferencesLoadedSelector = createSelector(
  globalStateSelector,
  global.getPreferencesLoaded
);
export const globalVariablesSelector = createSelector(
  globalStateSelector,
  global.getVariables
);
export const usernameSelector = createSelector(
  globalStateSelector,
  global.getUsername
);
export const accountNameSelector = createSelector(
  globalStateSelector,
  global.getAccountName
);
export const actionUNIDSelector = createSelector(
  globalStateSelector,
  global.getActionCompletedUNID
);
export const globalClickNotInElementSelector = createSelector(
  globalStateSelector,
  (state: global.State, el: HTMLElement) => {
    const notIsIn = global.getClickNotInElement(state, el);
    console.log('index, NOT IS IN', notIsIn, el);
    return notIsIn;
  }
);
export const globalVariableSelector = (name: string) => createSelector(
  globalVariablesSelector,
  (state: Record<string, string | number | boolean>) =>
    state[name]
);
export const globalDataSelector = createSelector(
  globalStateSelector,
  (state: global.State) => state?.[SECTION.DATA]
);
export const megaMenuSelector = () => createSelector(
  globalDataSelector,
  (data) => data?.[NAME.MENU]
);
export const breadcrumbsSelector = createSelector(
  globalDataSelector,
  (data) => data?.[NAME.BREADCRUMBS]
);
export const preferencesSelector = createSelector(
  globalDataSelector,
  (data) => data?.[SECTION.PERSISTENCE]
);
export const preferencesChangedSelector = (name: string, id?: string) => createSelector(
  preferencesSelector,
  (preferences: KeyValuePair[]) => preferences?.find(item => item.key === (id ? `${name}-${id}`: name))
);
export const preferenceValueSelector = (name: string, id?: string) => createSelector(
  preferencesSelector,
  (preferences: KeyValuePair[]) => preferences?.find(item => item.key === (id ? `${name}-${id}`: name))?.value
);
export const traceSelector = createSelector(
  globalStateSelector,
  global.getTrace
);
export const logLevelSelector = createSelector(
  globalStateSelector,
  global.getLogLevel
);
export const routingLockSelector = createSelector(
  globalStateSelector,
  global.getRoutingLock
);
