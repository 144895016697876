import { InjectionToken } from '@angular/core';
import { environment } from '@e/environment';
import { Inject } from '@angular/core';
import { ElementRef, OnInit } from '@angular/core';
import { Directive } from '@angular/core';
import { Destructor } from '@app/shared/destructor';
import { observeOnMutation } from '@app/utils';
import { CLASS_CROSS_WATCH, rgWipo, CLASS_CROSS, notBlockCross, UCASE, CURRENT_ENVIRONMENT, ENVIRONMENT_BY_SUBDOMAIN, computeCdnEnv } from '@app/utils/lib';
import { takeUntil, debounceTime } from 'rxjs/operators';


export const WINDOW = new InjectionToken<Window>('Window', {
  providedIn: 'root',
  factory: () => window,
});
@Directive({
  selector: '[cross-environment]'
})
export class CrossEnvironmentDirective extends Destructor implements OnInit {
  el: HTMLElement;

  constructor(private self: ElementRef, @Inject(WINDOW) private window: Window) { super(); }

  ngOnInit(): void {
    this.el = this.self.nativeElement;
    // watch subtree for changes (if we are not at PROD)
    if (CURRENT_ENVIRONMENT !== 'LIVE') {
      observeOnMutation(this.el, { childList: true, subtree: true })
      .pipe(
        takeUntil(this.destruction),
        debounceTime(100) // process no more often than 100ms
      )
      .subscribe(this.action.bind(this));
    }
  }
  action() {
    const aa = this.el.querySelectorAll('a:not(.ip-a-cross-watch)');
    Array.from(aa).forEach((a: HTMLAnchorElement) => {
      a.classList.add(CLASS_CROSS_WATCH);
      const url = (a.href || '');
      const m = url.match(rgWipo);
      if (m) {
        let target = UCASE(ENVIRONMENT_BY_SUBDOMAIN[m[1]]);
        if(target == 'UNKNOWN') {
          const computedValue = computeCdnEnv(target, m[1]);
          target = this.getEnvKey(target);
        }
        
        if (target && target.toUpperCase() !== CURRENT_ENVIRONMENT) {
          a.classList.add(CLASS_CROSS);
          a.addEventListener('click', this.blockCross.bind(this, target));
        }
      }
    });
  }
  blockCross(targetDomain: string, event: MouseEvent) {
    if (notBlockCross(targetDomain)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  getEnvKey(target: string): string {
    for (const [key, values] of Object.entries(environment.wipo_environments)) {
      if (values.includes(target)) {
        return key;
      }
    }
    return undefined;
  }
}