import { createAction, union } from '@ngrx/store';

export const setPageVariable = createAction(
    'set page variable',
    (
        name: string,
        variableName: string,
        data: string | number | boolean
    ) => ({
        name,
        variableName,
        data
    })
);
export const setPageDataSet = createAction(
    'set page dataset',
    (name: string, dataSetName: string, data: any) => ({
        name,
        dataSetName,
        data
    })
);

export const actions = union({ setPageVariable, setPageDataSet });
export type ActionTypes = typeof actions;
