import * as page from './actions';
import {
  mergeInState,
  replaceInState
} from '../lib';
import { NAME, PAGES, SECTION } from '../types';



export interface State {
  [id: string]: any;
}
export const StateInitial: State = {} as State;
export const getLanding =
  (s: State) => s.landing;

export function reducer(
  state: State = StateInitial,
  action: page.ActionTypes
): State {
  switch (action.type) {
    case page.setPageVariable.type: {
      return replaceInState(
        state,
        [action.name, SECTION.VARIABLES, action.variableName],
        action.data
      );
    }
    case page.setPageDataSet.type: {
      return mergeInState(
        state,
        [action.name, SECTION.DATA, action.dataSetName],
        action.data
      );
    }
    
    default:
      return state;
  }
}

