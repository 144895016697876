
import { Observable } from 'rxjs';
import { Helper } from './helper';
import { Lib } from './lib';

export * from './helper';
export * from './lib';

export const chunkInPages = Helper.chunkInPages;
export const copyDeep = Lib.copyDeep;
export const isClickIn = Lib.isClickIn;
export const isPointIn = Lib.isPointIn;
export const isBetween = Lib.isBetween;
export const formatLargeNumber = Lib.formatLargeNumber;
export const kebab2Camel = Lib.kebab2Camel;
export const asSerializable = Lib.asSerializable;

export const observeOnMutation = (target, config): Observable<MutationRecord[]> => {
  return new Observable((observer) => {
    const mutation = new MutationObserver((mutations, instance) => {
      observer.next(mutations);
    });
    mutation.observe(target, config);
    const unsubscribe = () => {
      mutation.disconnect();
    };
    return unsubscribe;
  });
};

