import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRouteSnapshot } from '@angular/router';

import { VersionComponent } from './pages/version/version.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthGuardOidc } from './guards/auth.guard.oidc';
import { resolve, ROUTE } from './shared/routing';
import { Store } from '@ngrx/store';
import { routingLockSelector, traceSelector } from './reducers';
import { Subscription } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { con, console } from '@shared';
import { TRACE } from './reducers/types';

const NOLOCK = new InjectionToken('no pop');
const checkIfNotLock = (store: Store) => (component: any,
  route: ActivatedRouteSnapshot) => {
  const current = route.url.join();
  return store.select(routingLockSelector).pipe(
    withLatestFrom(store.select(traceSelector)),
    tap(([locked, trace]) => {
      if (trace.routing) { console.warn(locked ? `Cannot deactivate Route: ${current}` : `Cool to deactivate Route: ${current}`); }
    }),
    map(([locked]) => !locked));
};

const routes: Routes = [
  {
    path: ROUTE.ROOT, loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
    canActivate: [AuthGuard], resolve
  },
  { path: ROUTE.HOME, loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule) },
  {
    path: ROUTE.DASHBOARD, loadChildren: () => import('./pages/dashboard-page.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuardOidc], resolve
  },
  // if going directly to DASHBOARD we keep #access_token
  { path: ROUTE.LOGIN, redirectTo: ROUTE.ROOT, pathMatch: 'full', resolve },
  { path: ROUTE.BUILD, component: VersionComponent, resolve },
  { path: ROUTE.LOGOUT, redirectTo: ROUTE.ROOT, pathMatch: 'full', resolve },
  {
    path: ROUTE.ALERTS, loadChildren: () => {
      console.log('ALERTS LAZY LOAD MODULE');
      const start = Date.now();
      return import('./pages/alerts-page.module').then(m => m.AlertsPageModule)
    .then(m => {
      console.log('ALERTS MODULE LOADED', `${((Date.now()-start)/1000).toFixed(2)}(s)`);
      return m;
    })},
    canActivate: [AuthGuardOidc], canDeactivate: [NOLOCK],
  },
  {
    path: ROUTE.ABOUT,
    loadChildren: () => import('./pages/about-page.module').then(m => m.AboutPageModule)
  },
  { path: '*', redirectTo: ROUTE.ROOT, pathMatch: 'full', resolve }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
})
  ],
  exports: [RouterModule], providers: [{
    provide: NOLOCK,
    useFactory: checkIfNotLock,
    deps: [Store]
  }]
})
export class AppRoutingModule {
  tracing: Subscription;
  constructor(private router: Router, private store: Store) {
    store.select(traceSelector).subscribe((trace: any) => {
      if (!this.tracing && trace?.routing) {
        this.tracing = this.router.events.subscribe((e) => {
          console.group(`Router Event: ${e.constructor.name}`);
          console.logAs(TRACE.ROUTING,'event:', e);
          console.groupEnd();
        });
      } else if (this.tracing && !trace.routing) {
        // stop
        this.tracing.unsubscribe();
        this.tracing = undefined;
      }
    });
  }
}

