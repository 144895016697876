export class Cookies {
  static set(key: string, value: string, expires?: Date): void {
    let cookieValue = `${key}=${value}`;
    if (expires) {
      cookieValue += `; expires='${expires.toUTCString()}'`;
    }
    cookieValue += `; path=/; domain=.wipo.int`;
    if (Cookies.get(key)) {
      // delete first, so we do not end with two values
      document.cookie = `${key}=${value};expires=${new Date(
        Date.now() - 1
      ).toUTCString()}; path=/; domain=.wipo.int`;
    }
    console.log('COOKIE, SET=', cookieValue);
    document.cookie = cookieValue;
  }
  static get(key: string): string {
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const pairs: string[] = decodedCookie.split(/;\s*/);

    const prefix = `${key}=`;
    for (const pair of pairs) {
      if (pair.indexOf(prefix) === 0) {
        return pair.substring(prefix.length);
      }
    }
    return '';
  }
  static setWithExpiryInSeconds(key: string, value: string, expires: number) {
    Cookies.setWithExpiryInMiliseconds(key, value, expires * 1000);
  }

  static setWithExpiryInMiliseconds(
    key: string,
    value: string,
    expires: number
  ) {
    Cookies.set(key, value, new Date(Date.now() + expires));
  }
}
