import * as widgets from './reducer';
import {
  createFeatureSelector, createSelector,
} from '@ngrx/store';

export const widgetStateSelector = createFeatureSelector<widgets.State>(
  'widgets'
);
export const widgetVariableSelector = createSelector(
  widgetStateSelector,
  (state: widgets.State, params: { id: string; name: string }) =>
    widgets.getWidgetVariable(state, params.id, params.name)
);

export const widgetDataSetSelector = (id: string, name: string) => createSelector(
  widgetStateSelector,
  (state: widgets.State) =>
    widgets.getWidgetDataSet(state, id, name)
);
export const widgetDataObjectSelector = (id: string, name: string) => createSelector(
  widgetStateSelector,
  (state: widgets.State) =>
    widgets.getWidgetDataObject(state, id, name)
);
export const widgetDataAsTextSelector = createSelector(
  widgetStateSelector,
  (state: widgets.State, params: { id: string; name: string }) =>
    widgets.getWidgetDataAsText(state, params.id, params.name)
);
export const widgetDataSetCachedUntilSelector = createSelector(
  widgetStateSelector,
  (state: widgets.State, params: { id: string; name: string, url: string }) =>
    widgets.getWidgetDataSetCachedUntil(state, params.id, params.name, params.url)
);
export const widgetDataSetErrorSelector = (id: string, name: string) => createSelector(
  widgetStateSelector,
  (state: widgets.State) =>
    widgets.getWidgetDataSetError(state, id, name)
);

