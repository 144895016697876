import * as widget from './actions';
import { WidgetData } from '@app/model/widget-data';
import {
  replaceInState,
  appendToArrayInState,
  replaceInStateArrayByKey,
  deleteDatasetItemInState,
  deleteInState,
  refreshArrayInState,
  replaceAsArrayInState,
  replaceKeyValuePairInStateArray,
  mergeInState
} from '../lib';

export interface State {
  data: { [id: string]: WidgetData };
  cached: {
    [id: string]: CacheTimestamp
  };
  errors: {
    [id: string]: any
  };
}
export const StateInitial: State = {
  data: {}
} as State;
export interface CacheTimestamp {
  expiry: number;
  url: string;
  params?: string;
}
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

const getDataById = (s: State, id: string) =>
  s && s.data ? s.data[id] : undefined;
const getCachedById = (s: State, id: string) =>
  s && s.cached ? s.cached[id] : undefined;
const getErrorsById = (s: State, id: string) =>
  s && s.errors ? s.errors[id] : undefined;
export const getWidgetVariable = (s: State, id: string, name: string) => {
  const data = getDataById(s, id);
  return data && data.variables ? data.variables[name] : undefined;
};
export const getWidgetDataSet = (s: State, id: string, name: string) => {
  const data = getDataById(s, id);
  return data && data.sets ? data.sets[name] : EMPTY_ARRAY;
};
export const getWidgetDataObject = (s: State, id: string, name: string) => {
  const data = getDataById(s, id);
  return data?.sets ? data.sets[name] : EMPTY_OBJECT;
};
export const getWidgetDataSetCachedUntil = (s: State, id: string, name: string, url: string) => {
  const cached = getCachedById(s, id);
  return (cached !== undefined ? cached[name] : {expiry: 0}) as CacheTimestamp;
};
export const getWidgetDataSetParams = (s: State, id: string, name: string, url: string, params: any) => {
  const cached = getCachedById(s, id);
  return (cached !== undefined ? cached[name] : {expiry: 0}) as CacheTimestamp;
};
export const getWidgetDataAsText = (s: State, id: string, name: string) => {
  const data = getDataById(s, id);
  return data?.sets ? data.sets[name] : '';
};
export const getWidgetDataSetError = (s: State, id: string, name: string) => {
  const errors = getErrorsById(s, id);
  return (errors !== undefined ? errors[name] : undefined);
};
export const getPersistedValue = (s: State, key: string) => {
  const data = getDataById(s, 'global');
  const persisted = data && data.sets ? data.sets['persistence'] : EMPTY_ARRAY;
  return persisted.find(item => item.key === key);
};
export function reducer(
  state: State = StateInitial,
  action: widget.ActionTypes
): State {
  switch (action.type) {
    case widget.setData.type: {
      return replaceInState(state, ['data', action.widgetName], action.data);
    }
    case widget.setWidgetVariable.type: {
      return replaceInState(
        state,
        ['data', action.widgetName, 'variables', action.variableName],
        action.data
      );
    }
    case widget.setWidgetDataSet.type: {
      return replaceAsArrayInState(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.data
      );
    }
    case widget.setWidgetDataObject.type: {
      return replaceInState(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.data
      );
    }
    case widget.setWidgetDataAsText.type: {
      return replaceInState(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.data
      );
    }
    case widget.resendWidgetDataSet.type: {
      return refreshArrayInState(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName]
      );
    }
    case widget.resendAll.type: {
      return {...state};
    }
    case widget.discardWidgetDataSetCache.type: {
      return replaceInState(
        state,
        ['cached', action.widgetName, action.dataSetName], {expiry: 0}
      );
    }
    case widget.clearAllWidgetDataSetCache.type: {
      return {
        ...state,
        cached: {},
        data: {},
        errors: {}
      };
    }
    case widget.clearWidgetData.type: {
      return {
        ...state,
        cached: deleteInState(state.cached, [action.widgetName]),
        errors: deleteInState(state.errors, [action.widgetName]),
        data: deleteInState(state.data, [action.widgetName])
      };
    }
    case widget.setWidgetDataSetCachedUntil.type: {
      return replaceInState(
        state,
        ['cached', action.widgetName, action.dataSetName],
        {expiry: action.until, url: action.url}
      );
    }
    case widget.setWidgetDataSetParams.type: {
      return replaceInState(
        state,
        ['cached', action.widgetName, action.dataSetName],
        {expiry: action.until, url: action.url, params: JSON.stringify(action.payLoad)}
      );
    }
    case widget.setWidgetDataSetError.type: {
      return replaceInState(
        state,
        ['errors', action.widgetName, action.dataSetName],
        {error: action.error}
      );
    }
    case widget.clearWidgetDataSetError.type: {
      return deleteInState(
        state,
        ['errors', action.widgetName, action.dataSetName]
      );
    }
    case widget.deleteWidgetDataSetItem.type: {
      return deleteDatasetItemInState(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.keyName,
        action.itemName
      );
    }
    case widget.appendWidgetDataSet.type: {
      return appendToArrayInState(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.data
      );
    }
    case widget.appendWidgetDataObject.type: {
      return mergeInState(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.data, 
        action.transform
      );
    }
    
    case widget.upsertWidgetDataSet.type: {
      return replaceInStateArrayByKey(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.key,
        action.data
      );
    }
    case widget.upsertKeyValuePairInWidgetDataSet.type: {
      return replaceKeyValuePairInStateArray(
        state,
        ['data', action.widgetName, 'sets', action.dataSetName],
        action.data
      );
    }
    default:
      return state;
  }
}
