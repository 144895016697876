<div style='width: 600px; height: 200px;position: absolute; 
top: calc(50vh - 100px);left: calc(50vw - 300px);'>
    <div style='display: table;font-size: 1.5em;'>
        <div style='display: table-row-group'>
            <div style='display: table-cell'>
                Version:
            </div>
            <div style='display: table-cell'>
                {{build.version}}
            </div>
        </div>
        <div style='display: table-row-group'>
            <div style='display: table-cell'>
                Branch:
            </div>
            <div style='display: table-cell'>
                {{build.branch}}
            </div>
        </div>
        <div style='display: table-row-group'>
            <div style='display: table-cell'>
                Revision:
            </div>
            <div style='display: table-cell'>
                {{build.revision}}
            </div>
        </div>
        <div style='display: table-row-group'>
            <div style='display: table-cell'>
                Built on:
            </div>
            <div style='display: table-cell'>
                {{build.builtOn}}
            </div>
        </div>
        <div style='display: table-row-group'>
            <div style='display: table-cell'>
                Environment:
            </div>
            <div style='display: table-cell'>
                {{build.environment}}
            </div>
        </div>
    </div>
    <div class="ip-input__button-wrapper">
        <button class="ip-button-medium ip-button--is-type_primary" (click)="goHome()">Close</button>
    </div>
</div>