import { createAction, union } from '@ngrx/store';
import { KeyValuePair, ObjectLiteral, WidgetData, WidgetDataObject } from '@app/model/widget-data';
import { RequestTracker } from '@app/model/request-tracker';
import { DatasetTo, NamedWidgetDatasetFromItem, WidgetDataset, WidgetDatasetFrom, WidgetDatasetTo } from '../types';
import { environment } from '@e/environment';

export const setData = createAction(
  'set widget data',
  (widgetName: string, value: WidgetData) => ({
    widgetName: widgetName,
    data: value
  })
);
export const setWidgetVariable = createAction(
  'set widget variable',
  (
    widgetName: string,
    variableName: string,
    value: string | number | boolean
  ) => ({
    widgetName: widgetName,
    variableName: variableName,
    data: value
  })
);
export const setWidgetDataSet = createAction(
  'set widget dataset',
  (widgetName: string, dataSetName: string, data: WidgetDataObject[]) => ({
    widgetName: widgetName,
    dataSetName: dataSetName,
    data: data
  })
);
export const setWidgetDataObject = createAction(
  'set widget data object',
  (widgetName: string, dataSetName: string, data: WidgetDataObject) => ({ widgetName, dataSetName, data })
);
export const setWidgetDataAsText = createAction('set widget data as text',
  (widgetName: string, dataSetName: string, data: string) => ({ widgetName, dataSetName, data })
);
export const setWidgetDataSetCachedUntil = createAction(
  'set widget dataset cached until',
  (widgetName: string, dataSetName: string, until: number, url: string) => ({
    widgetName: widgetName,
    dataSetName: dataSetName,
    until: until, url: url
  })
);
export const setWidgetDataSetParams = createAction(
  'set widget dataset params',
  (widgetName: string, dataSetName: string, until: number, url: string, payLoad: any) => ({
    widgetName: widgetName,
    dataSetName: dataSetName,
    until: until, url: url,
    payLoad: payLoad
  })
);
export const setWidgetDataSetError = createAction(
  'set widget dataset error',
  (widgetName: string, dataSetName: string, error: any) => ({ widgetName, dataSetName, error })
);
export const clearWidgetDataSetError = createAction(
  'clear widget dataset error',
  (widgetName: string, dataSetName: string) => ({ widgetName, dataSetName})
);
export const appendWidgetDataSet = createAction(
  'append widget dataset',
  (widgetName: string, dataSetName: string, data: WidgetDataObject[]) => ({
    widgetName: widgetName,
    dataSetName: dataSetName,
    data: data
  })
);
export const appendWidgetDataObject = createAction(
  'append widget data object',
  (widgetName: string, dataSetName: string, data: WidgetDataObject, transform?: (data: any) => any) => ({
    widgetName, dataSetName, data, transform
  })
);
export const upsertPersistWidgetDataSet = createAction(
  'upsert persist widget dataset',
  (
    widgetName: string,
    dataSetName: string,
    data: WidgetDataObject[],
    key: string,
    url?: string,
    contentType?: string
  ): (DatasetTo & WidgetDataset) => ({
    widgetName: widgetName,
    dataSetName: dataSetName,
    data: data,
    url: url,
    key: key,
    contentType: contentType
  })
);
export const deletePersistWidgetDataSetItem = createAction(
  'delete persist widget dataset item',
  (
    widgetName: string,
    dataSetName: string,
    keyName: string,
    itemName: string,
    url: string
  ): NamedWidgetDatasetFromItem => ({
    widgetName,
    dataSetName,
    keyName,
    itemName,
    url
  })
);
export const deleteWidgetDataSetItem = createAction(
  'delete widget dataset item',
  (
    widgetName: string,
    dataSetName: string,
    keyName: string,
    itemName: string
  ) => ({
    widgetName: widgetName,
    dataSetName: dataSetName,
    keyName: keyName,
    itemName: itemName
  })
);
export const upsertWidgetDataSet = createAction(
  'upsert widget dataset',
  (
    widgetName: string,
    dataSetName: string,
    data: WidgetDataObject[],
    key: string
  ) => ({
    widgetName,
    dataSetName,
    data,
    key
  })
);
export const upsertKeyValuePairInWidgetDataSet = createAction(
  'upsert key-value pair in widget dataset',
  (
    widgetName: string,
    dataSetName: string,
    data: KeyValuePair
  ) => ({
    widgetName,
    dataSetName,
    data
  })
);
export const persistByKey = createAction(
  'persist widget dataset',
  (
    data: any,
    key: string,
    url: string,
    unid?: string,
    contentType: string = 'application/json'
  ) => ({
    data,
    url,
    key,
    unid,
    contentType
  })
);
export const persistDeleteByKey = createAction(
  'persist delete',
  (
    url: string,
    key: string
  ) => ({
    url,
    key
  })
);
export const clearWidgetDataSet = createAction(
  'clear widget dataset',
  (widgetName: string, dataSetName: string): WidgetDataset => ({
    widgetName: widgetName,
    dataSetName: dataSetName
  })
);
export const resendWidgetDataSet = createAction(
  'resend widget dataset',
  (widgetName: string, dataSetName: string): WidgetDataset => ({
    widgetName,
    dataSetName
  })
);
export const resendAll = createAction(
  'resend all',
  () => ({
    all: true
  })
);
export const clearWidgetData = createAction(
  'clear widget data',
  (widgetName: string) => ({
    widgetName: widgetName
  })
);
export const discardWidgetDataSetCache = createAction(
  'discard widget dataset cache',
  (widgetName: string, dataSetName: string) => ({
    widgetName: widgetName,
    dataSetName: dataSetName
  })
);
export const clearAllWidgetDataSetCache = createAction(
  'clear all widget dataset cache'
);
export const loadWidgetDataSet = createAction(
  'load widget dataset',
  (
    widgetName: string,
    dataSetName: string,
    url: string
  ): WidgetDatasetFrom => ({
    widgetName,
    dataSetName,
    url
  })
);
export const loadWidgetDataObject = createAction(
  'load widget data object',
  (
    widgetName: string,
    dataSetName: string,
    url: string,
    transform?: (data: any) => any // tranformation
  ): WidgetDatasetFrom => ({
    widgetName,
    dataSetName,
    url,
    transform
  })
);
export const loadAppendWidgetDataObject = createAction(
  'load append widget dataobject',
  (
    widgetName: string,
    dataSetName: string,
    url: string,
    transform: (data: any) => any // tranformation
  ): WidgetDatasetFrom => ({
    widgetName,
    dataSetName,
    url,
    transform
  })
);
export const loadAppendWidgetDataSet = createAction(
  'load append widget dataset',
  (
    widgetName: string,
    dataSetName: string,
    url: string
  ): WidgetDatasetFrom => ({
    widgetName,
    dataSetName,
    url
  })
);
export const loadWidgetDataAsText = createAction(
  'load widget data as text',
  (
    widgetName: string,
    dataSetName: string,
    url: string
  ) => ({ widgetName, dataSetName, url })
);
export const postWidgetData = createAction(
  'post widget data',
  (
    widgetName: string,
    dataSetName: string,
    payLoad: ObjectLiteral,
    url: string
  ): WidgetDatasetTo => ({ widgetName, dataSetName, payLoad, url })
);
export const loadWidgetDataSetWithCache = createAction(
  're-load widget dataset',
  (
    widgetName: string,
    dataSetName: string,
    url: string,
    cacheMs = environment.caching.default,
    discardCache: boolean = false
  ) => ({
    widgetName,
    dataSetName,
    url,
    cacheMs,
    discardCache
  })
);
export const postWidgetDataSet = createAction(
  'Post widget dataset',
  (
    widgetName: string,
    dataSetName: string,
    url: string,
    cacheMs: number,
    data: any,
    discardCache: boolean = false
  ) => ({
    widgetName,
    dataSetName,
    url,
    cacheMs,
    data: data,
    discardCache
  })
);
export const loadWidgetDataAsTextWithCache = createAction(
  're-load widget dataset as text',
  (
    widgetName: string,
    dataSetName: string,
    url: string,
    cacheMs: number,
    discardCache: boolean = false
  ) => ({
    widgetName,
    dataSetName,
    url,
    cacheMs,
    discardCache
  })
);
export const sendRequestTracker = createAction(
  'send request tracker',
  (
    tracker: RequestTracker
  ) => ({
    tracker: tracker
  })
);
export const loadWidgetActions = union({

});
export const actions = union({
  setData,
  setWidgetVariable,
  setWidgetDataSet,
  setWidgetDataObject,
  setWidgetDataAsText,
  setWidgetDataSetCachedUntil,
  setWidgetDataSetParams,
  setWidgetDataSetError,
  clearWidgetDataSetError,
  resendWidgetDataSet,
  resendWidgetDataAsText: resendAll,
  appendWidgetDataSet,
  appendWidgetDataObject,
  clearWidgetDataSet,
  clearWidgetData,
  loadWidgetDataSet,
  loadWidgetDataObject,
  loadAppendWidgetDataSet,
  loadAppendWidgetDataObject,
  loadWidgetDataSetWithCache,
  loadWidgetDataAsText,
  loadWidgetDataAsTextWithCache,
  discardWidgetDataSetCache,
  clearAllWidgetDataSetCache,
  upsertPersistWidgetDataSet,
  persistByKey,
  persistDeleteByKey,
  upsertWidgetDataSet,
  upsertKeyValuePairInWidgetDataSet,
  deletePersistWidgetDataSetItem,
  deleteWidgetDataSetItem,
  sendRequestTracker
});
export type ActionTypes = typeof actions;
export type loadWidgetActionTypes = typeof loadWidgetDataSet | typeof loadWidgetDataObject | typeof loadAppendWidgetDataSet | typeof loadAppendWidgetDataObject;
export type setWidgetDateActionTypes = typeof setWidgetDataSet | typeof setWidgetDataAsText | typeof setWidgetDataObject | typeof appendWidgetDataSet | typeof appendWidgetDataObject;