import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomMissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams && params.interpolateParams.hasOwnProperty("Default")) {
      if (!params.interpolateParams["Default"]) {
        return '';
      }
      return params.interpolateParams["Default"];
    }
    return params.key;
  }
}