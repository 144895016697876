import { Point } from '@angular/cdk/drag-drop';
import { environment } from '@e/environment';
import { CustomEvents } from '@app/shared';
import { ROUTE } from '@app/shared/routing';
export class Lib {
  // will build here copy utilities
  // deep copy apart from support for internal JS objects (Date)
  // if asType provided create from particular prototype
  static copyDeep<T>(o: T | T[], asType?: any, cached = new WeakMap<any, any>()) {
    if (cached.has(o)) { return cached.get(o); }
    if (Array.isArray(o)) {
      return o.map(v => Lib.copyDeep<T>(v, asType, cached));
    } else if (o === null || o === undefined) {
      return o;
    } else if (typeof o === 'object') {
      const copy = Object.create(
        asType && asType.prototype ? asType.prototype : Object.getPrototypeOf(o)
      );
      cached.set(o, copy);
      Object.entries(o).forEach(([key, child]) => {
        const childCopy = Lib.copyDeep(child, undefined, cached);
        copy[key] = childCopy;
      });
      // convention to have 'init' method if we want to initialize
      if (copy['init'] && typeof copy['init'] === 'function') {
        copy.init();
      }
      return copy;
    } else {
      return o;
    }
  }
  static isClickIn(click: MouseEvent, el: HTMLElement) {
    return click && el && Lib.isPointIn(
      {
        x: click.clientX,
        y: click.clientY
      },
      el
    );
  }
  static isPointIn(click: Point, el: HTMLElement) {
    const right = el.offsetLeft + el.clientWidth;
    const bottom = el.offsetTop + el.clientHeight;
    const isIn = Lib.isBetween(click.x, el.offsetLeft, right) &&
      Lib.isBetween(click.y, el.offsetTop, bottom);
    return isIn;
  }
  static isBetween(point: number, a: number, b: number) {
    return point >= a && point <= b;
  }
  static formatLargeNumber(n: number) {
    if (typeof n !== 'number') { return ''; }
    if (n < 1e3) { return `${n}`; }
    if (n >= 1e3 && n < 1e6) { return +(n / 1e3).toFixed(1) + 'K'; }
    if (n >= 1e6 && n < 1e9) { return +(n / 1e6).toFixed(1) + 'M'; }
    if (n >= 1e9 && n < 1e12) { return +(n / 1e9).toFixed(1) + 'B'; }
    if (n >= 1e12) { return +(n / 1e12).toFixed(1) + 'T'; }
  }
  static kebab2Camel(str: string) {
    return str.replace(/\b-([a-z])/g, (_, char) => char.toUpperCase());
  }

  static asSerializable(o: any): any {
    return JSON.parse(JSON.stringify(o, replaceSomeObjects));
  }
}
function replaceSomeObjects(keyAny: string, value: any) {
  if (value instanceof Error || value instanceof Map) {
    const error = {};
    Object.getOwnPropertyNames(value).forEach((key) => {
      error[key] = value[key];
    });
    return error;
  }
  return value;
}

export const dispatchCustomEvent = (name: string, detail: any) => {
  const e = new CustomEvent(name, {
    bubbles: true,
    detail
  });
  document.dispatchEvent(e);
};
export const dispatchRoutingFinished = (linkCode?: ROUTE) => {
  dispatchCustomEvent(CustomEvents.ROUTING_FINISHED, { value: 1, linkUrl: document.location.href, linkCode });
};
// cross domain handler: init, methods
export const domainFullName = (targetDomain: string) => {
  return targetDomain === 'LIVE' ? 'LIVE (production)' : targetDomain;
};
export const UCASE = (text: string): string => {
  return text ? text.toUpperCase() : 'UNKNOWN';
};
export const notBlockCross = (targetEnvironment: string, event?: MouseEvent) => {
  if (confirm(`You are now in the ${domainFullName(CURRENT_ENVIRONMENT)} environment; the link you clicked on belongs to an application that is not available in this environment.

By continuing, you will change the environment to ${domainFullName(targetEnvironment)}!

Please note that changing environments means accessing different data sets.`)) {
    return true;
  } else {
    if (event) { event.preventDefault(); }
    return false;
  }
};

export const CLASS_CROSS = 'ip-a-cross';
export const CLASS_CROSS_WATCH = 'ip-a-cross-watch';
export const WIPO_INT = 'wipo.int';
export const rgWipo = `http(?:.?)://(.*?)\.${WIPO_INT}`;
// INIT
const d = window.location.hostname;
const subdomain = d.substring(0, d.indexOf(WIPO_INT) - 1);
export const ENVIRONMENT_BY_SUBDOMAIN: { [key: string]: string } = {};
// convert to straight lookup table
for (const env of Object.keys(environment.wipo_environments)) {
  environment.wipo_environments[env].forEach((domain: string) => {
    ENVIRONMENT_BY_SUBDOMAIN[domain] = env;
  });
}

const env = UCASE(ENVIRONMENT_BY_SUBDOMAIN[subdomain]);

export const computeCdnEnv = (env, subdomain) => {
  return (env === 'UNKNOWN' && subdomain.startsWith('cdn'))
    ? subdomain.split('.').slice(-2, -1).pop()
    : undefined;
};

// if unkown, we probably dealing with a cdn URL
const cdnEnv =  computeCdnEnv(env, subdomain);

export const CURRENT_ENVIRONMENT = (cdnEnv)
  ? UCASE(ENVIRONMENT_BY_SUBDOMAIN[cdnEnv])
  : env;

export const blockUrl = (url: string) => {
  if (CURRENT_ENVIRONMENT !== 'LIVE' && url) {
    const m = url.match(rgWipo);
    if (m) {
      const partial = Object.keys(ENVIRONMENT_BY_SUBDOMAIN).find(k => url.indexOf(k) > -1);
      const targetEnv =
        UCASE(
          // partial match; AWS environments
          ENVIRONMENT_BY_SUBDOMAIN[partial] ||
          // full match - full prefix before .wipo.int; on premises servers
          ENVIRONMENT_BY_SUBDOMAIN[m[1]]
        );
      if (targetEnv !== CURRENT_ENVIRONMENT) {
        if (!notBlockCross(targetEnv)) {
          return true;
        }
      }
    }
  }
  return false;
};
export const openLink = (url: string, source: string) => {
  sendLinkClickedEvent(CustomEvents.LINK_CLICKED, url, source);
  if (url && !blockUrl(url)) {
    window.location.href = url;
  }
};

export const openWindow = (url: string, source: string, tab?: string) => {
  sendLinkClickedEvent(CustomEvents.LINK_CLICKED, url, source);
  if (url && !blockUrl(url)) {
    window.open(url, tab);
  }
};

export const sendLinkClickedEvent = (event: CustomEvents, url: string, source: string) => {
  const linkClickEvent = new CustomEvent(event, {
    bubbles: true,
    detail: {
      value: url,
      text: source
    }
  });
  console.log('E', linkClickEvent);
  document.dispatchEvent(linkClickEvent);
};
/* url localisation */
export const UN6 = ['ar', 'en', 'es', 'fr', 'ru', 'zh'];
const EN = new RegExp('/en(?=($|/))');
const ENUPOV = new RegExp('\.en(?=$)'); // UPOV
const ENFR = new RegExp('/en/fr/'); // special case in locarno
const PARAM_EN = new RegExp(/\?lang=en/);
export const translateUrl = (url: string, lang: string, available = environment.availableLanguages) => {
  if (lang !== 'en' && (EN.test(url) || ENUPOV.test(url)) && !ENFR.test(url) && available.includes(lang)) {
    while (EN.test(url)) {
      url = url.replace(EN, `/${lang}`);
    }
    while (ENUPOV.test(url)) {
      url = url.replace(ENUPOV, `.${lang}`);
    }
    return url;
  } else if (PARAM_EN.test(url) && available.includes(lang)) {
    return url.replace(PARAM_EN, `?lang=${lang}`);
  } else { return url; }
};
/* other */
export const clickLink = (e: MouseEvent, action: () => void) => {
  const t = e.target as HTMLElement;
  if (t.tagName === 'A') {
    action();
  }
};
export const isEmptyDataset = (data: any) => {
  return !data || (Array.isArray(data) && data.length === 0);
};
export const copyDeepPlain = (o: any) => {
  if (Array.isArray(o)) {
    return o.map(v => copyDeepPlain(v));
  } else if (o === null || o === undefined) {
    return o;
  } else if (typeof o === 'object') {
    const copy = Object.create(null);
    Object.keys(o).forEach(key => {
      copy[key] = copyDeepPlain(o[key]);
    });
    return copy;
  } else {
    return o;
  }
}
export const supportedLanguages  = ['es', 'fr'];
export const getDisplayLang = (lang: string): string => {
  return supportedLanguages.includes(lang) ? lang : 'en';
};
