import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WidgetDataService {
  constructor(private httpClient: HttpClient) {}
  getBlob(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }
  getAll(url: string): Observable<any|any[]> {
    return this.httpClient.get<any[]>(url);
  }
  getAny(url: string, options?:any): Observable<any> {
    return this.httpClient.get(url, options);
  }
  post(url: string, obj: any, type?: string): Observable<any> {
    if (type === 'text/plain') {
      const headers = { 'content-type': type };
      return this.httpClient.post(`${url}`, obj, { headers: headers });
    } else {
      return this.httpClient.post(`${url}`, obj);
    }
  }
  delete(url: string, id: string): Observable<any> {
    // we cannot DELETE in cross-domain API (again prob with OPTIONS)
    return this.post(url, {key: id}, 'text/plain');
    // return this.httpClient.delete(`${url}/${id}`);
  }
  putAll(
    url: string,
    key: string,
    value: any,
    type?: string
  ): Observable<any>[] {
    // we switch form all PUT to all POST to avoid OPTIONS calls on cross-domain APIs
    // return value.map(v => this.put(url, v[key], v, type));
    return Array.isArray(value) ? value.map(v => this.post(url, v, type)) : [this.post(url, value, type)];
  }
}
