import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { MockInterceptor } from './mock.interceptor';
import { NoopInterceptor } from './noop.interceptor';
import { environment } from 'environments/environment';

const useMock = environment.mockInterceptor;
if (useMock) { console.warn('Using MockInterceptor'); }
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: useMock ? MockInterceptor : NoopInterceptor, multi: true }
];
