import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
/* we make sure that click on routerLink execute in the next cycle*/
export class WaitResolver  {
resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return interval(1).pipe(take(1));
  }
}
