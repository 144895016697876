// WIPO acceptance environment

import { common_environment } from './common';

export const environment = {
  ...common_environment,
  /* common overrides */
  name: 'ACC',
  trace: { // initial state
    store: true,
    routing: false,
    authentication: true,
    events: false
  },
  webcomponentsCdnJs: 'https://web.cdnjs.webcms.acc.web1.wipo.int/universal-lf-webcomponents',
  footerVersion: 'latest',
  
  hostEPCT: 'https://www5.wipo.int',
  hostEPCTUI: 'https://pcttest.wipo.int',

  urlNewsLetters: 'https://www5.wipo.int/resource-manager/api/v1/ipportal/',

  omitWidgets: [],
  urlPersistanceUNICC: 'https://www5.wipo.int/portal-persistence/api/v1/preferences',
  urlPersistanceAWS: 'https://api.ipp-persistence.ipportal.acc.web1.wipo.int/v1/preferences',

  /* General settings */
  production: true,
  acceptance: true,

  /* Authentication settings */
  oidcUserinfo: 'https://www5.wipo.int/am/oauth2/userinfo',
  oidcIssuer: 'https://www5.wipo.int/am/oauth2',
  oidcClientId: 'gippPortalAcc',
  oidcRedirectUri: window.location.origin + '/ipportal/login',
  oidcPostLogoutRedirectUri: 'https://www5.wipo.int/wipoaccounts/generic/public/logout.xhtml?returnURL=https://www5.wipo.int/ipportal',
  oidcSilentRedirectUri: window.location.origin + '/ipportal/silent-refresh.html',
  oidcScope: 'openid profile email loa office',

  ssoCookieName: 'amassocookie',

  homeVideo: 'https://www.wipo.int/portal/en/news/2019/article_0029.html',
  homeOnboarding: 'https://www.wipo.int/portal/en/news/2019/article_0029.html',

  /* WIPO application links */
  wipoAccountUrl: 'https://www5.wipo.int/wipoaccounts/generic',
  ePay2Url: 'https://www5.wipo.int/epayweb/v2',
  epAyPaymentDetailUrl: 'https://www5.wipo.int/epayweb/v2/status.xhtml?trackingNumber=',
  currentaccountUrl: 'https://www3-test.wipo.int/currentaccount/private/index.xhtml',
  currentaccountCreateUrl: 'https://www3-test.wipo.int/currentaccount/private/forms/create.xhtml',
  ePCTUrl: 'https://pcttest.wipo.int/ePCT/',
  wipoProofUrl: 'https://wdts.pct.acc.web1.wipo.int/wdts/',
  faqUrl: 'https://www-test.wipo.int/ipportal-support/ipportal/faq',

  /* Madrid Monitor in common (all links to PROD) */
  globalBrandsDbUrl: 'https://website.gbd.globaldb.acc.web1.wipo.int/en/',
  distributionReportsPairingUrl: 'https://www3-test.wipo.int/feedistribution/private/login/login.xhtml',
  distributionReportsDefinitions: 'https://www3-test.wipo.int/feedistribution/private/pages/definitions.xhtml',
  madridMPMUrl: 'TBD',
  patentscopeUrl: 'https://patentscope.wipo.int/nc',
  wipoPearlUrl: 'https://wipopearl.wipo.int/en/',

  /* WIPO API's links */
  ePay2APIUrl: 'https://www5.wipo.int/epayweb/rest',
  currentaccountAPIUrl: 'https://www3-test.wipo.int/currentaccount/private/rest/UserService/customer',
  currentAccountTransactionUrl: 'https://www3-test.wipo.int/currentaccount/private/rest/TransactionService/transactions',
  madridMonitorAPIUrl: 'https://www.wipo.int/madrid/monitor/api/v1/query',
  madridMonitorWatchedIRNsAPIUrl: 'https://www3.wipo.int/mea/mea.jsp?cmd=list',
  globalDesignDbAPIUrl: 'https://www5.wipo.int/bnd-api/gipp/v1/design',
  globalBrandDbAPIUrl: 'https://public-api.gbd.globaldb.acc.web1.wipo.int/gipp',
  patentscopeAPIUrl: 'https://patentscope.wipo.int/nc/endpoint/portal',
  patentscopeQueriesAPIUrl: 'https://patentscope.wipo.int/nc/endpoint/portal',
  wipoPearlAPIUrl: 'https://restapi.wipopearl.pct.acc.web1.wipo.int/wipopearl/rest/',
  wipoPearlSuggestedAPIUrl: 'https://restapi.wipopearl.pct.acc.web1.wipo.int/wipopearl/conceptmap?getConceptTermItemList',
  alertsAPIUrl: 'https://www5.wipo.int/alerts-manager/api/v2/alerts',
  multipleAlertsAPIUrl: 'https://www5.wipo.int/alerts-manager/api/v2/alerts-collection',
  multipleCaseAlertsAPIUrl: 'https://webaccess.wipo.int/caseusernotification/api/v1/alerts-collection',
  alertsPCT_APIUrl: 'https://www5.wipo.int/ePCT-API-IPPORTAL-details/alerts',
  alertsPCT_API_basic: 'https://www5.wipo.int/ePCT-API-IPPORTAL-basic/alerts?checkEmpty=false',
  caseAlertAPIUrl: 'https://webaccess.wipo.int/caseusernotification/api/v1/alerts',

  distributionReportsAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/getdocuments/filter/',
  distributionReports_LastAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/getdocument/latest',
  distributionReports_docAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/download/document/',
  distributionReports_accessAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/document/getuseraccess',

  webcomponentsPath: window.location.hostname === 'www5.wipo.int' ? `//${window.location.hostname}/webcomponents` : 'https://cdn.ipp-navbar.ipportal.acc.web1.wipo.int',

  newNavbar: false,
  contextIsRoot: false,
  debugStore: false,

  landing: {
    pct: 'https://pcttest.wipo.int/ePCT/',
    madrid: 'https://cdn.root.madrid.acc.web1.wipo.int/',
    hague: 'https://hague.wipo.int/#/landing/home',
    lisbon: 'https://www.wipo.int/lisbon/en/'
  },
  /* TODO make sure enabled only in English */
  enableSmartlingCapture: true,
  stats: {
    ...common_environment.stats,
    dbinfo: 'https://www5.wipo.int/branddb/dbInfo.js'
  },
  contributionDocsAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/getdocuments/filter/contribution/',
  contributionDocs_LastAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/getdocument/latest/contribution/',
  contributionDocs_docAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/download/document/',
  contributionDocs_accessAPIUrl: 'https://www3-test.wipo.int/feedistribution/private/rest/DocumentWebService/document/getuseraccess',
  contributionDocs_PairingUrl: 'https://www3-test.wipo.int/feedistribution/private/login/login.xhtml',
  contributionDocs_regulations: 'https://www3-test.wipo.int/feedistribution/private/pages/index.xhtml?page=regulations',

  hsmpWebAppsUrl: 'https://www5.wipo.int/hague/memberprofiles/',
  hsmpAPIUrl: 'https://web.webcms.webcms.acc.web1.wipo.int/o/api/v1/hsmp-api/',
  wipolexAPIUrl: 'https://cdn.api.wipolex.sdd.acc.web1.wipo.int/',
  wipolexWebAppsUrl: 'https://cdn.wipolex.sdd.acc.web1.wipo.int/',
  msmpWebAppsUrl: 'https://www-test.wipo.int/madrid/memberprofiles/',
  msmpWebAPIUrl: 'https://www-test.wipo.int/madrid/memberprofiles-api/api/',

  //to be removed when final version is approved
  feedbackGizmo: 'https://surveys.wipo.int/s3/Survey-on-WIPO-IP-Portal-ULF-test-copy',
};
