import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, NEVER, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@reducers/global/reducer';
import {
  authenticatedSelector,
  authenticationLoadingSelector,
} from '@reducers';
import { take, map, tap, switchMap } from 'rxjs/operators';
import { ROUTE } from '@app/shared/routing';
import { TRACE } from '@reducers/types';
import { console } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  instanceId = `root-${Math.ceil(Math.random() * 100000)}`;
  url: string;
  constructor(private router: Router, private store: Store<State>) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const self = this.selfWOFragment(state.url);
    this.url = state.url;
    console.logAs(TRACE.ROUTING, `guard ${this.instanceId} ${this.url} ROOT: ${state.url} -> ${self}`);
    return this.entryRoute(self);
  }
  selfWOFragment(url: string): boolean | UrlTree {
    const idx = url.indexOf('#');
    return idx > -1 ? this.router.parseUrl(url.substr(0, idx)) : true;
  }
  entryRoute(self: boolean | UrlTree): Observable<UrlTree | boolean> {
    const authLoading = this.store.select(authenticationLoadingSelector);
    const authStatus = this.store.select(authenticatedSelector);

    // deals only with root path
    return combineLatest([authStatus, authLoading]).pipe(
      tap(([auth, loading]) => {
        console.logAs(TRACE.ROUTING,
          `guard ${this.instanceId} ROOT auth state: ${auth}, loading: ${loading}`);
      }),
      switchMap(([auth, loading]) => (loading ? NEVER : of(auth))),
      tap(state => { console.logAs(TRACE.ROUTING, `guard ROOT auth state: ${state}`); }),
      // switchMap(state =>)
      take(1),
      map(authenticated => {
        // redirect to dashboard if authenticated
        console.logAs(TRACE.ROUTING, `guard ${this.instanceId} ROOT auth state, before redirect: ${authenticated}`);
        const route = authenticated ? this.router.parseUrl(`/${ROUTE.DASHBOARD}`) : self;
        const trace = authenticated ? `/${ROUTE.DASHBOARD}` : true;
        console.warnAs(TRACE.ROUTING,
          `guard ${this.instanceId} ROOT ${this.url} authenticated: ${authenticated}, return:`, trace);
        return route;
      })
    );
  }
}
