import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@e/environment';

@Injectable({
  providedIn: 'root'
})
export class OidcHelperService {

  constructor(private http: HttpClient) {}

  async isAuthorizationCodeFound(redirectUrl: string): Promise<boolean> {
    try {
      const { nonce, state, codeChallenge } = await this.generatePKCEParams();
      await this.fetchAuthorizationCode(redirectUrl, nonce, state, codeChallenge);
      return true;
    } catch (error) {
      return false;
    }
  }

  private async fetchAuthorizationCode(redirectUrl: string, nonce: string, state: string, codeChallenge: string): Promise<string> {
    const authorizationEndpoint = `${environment.oidcIssuer}/authorize?` +
      `client_id=${encodeURIComponent(environment.oidcClientId)}` +
      `&redirect_uri=${encodeURIComponent(redirectUrl)}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent(environment.oidcScope)}` +
      `&code_challenge=${encodeURIComponent(codeChallenge)}` +
      `&code_challenge_method=S256` +
      `&nonce=${encodeURIComponent(nonce)}` +
      `&response_mode=form_post` +
      `&state=${encodeURIComponent(state)}`;

    try {
      const response = await this.http.get(authorizationEndpoint, {
        withCredentials: true,
        observe: 'response',
        responseType: 'text'
      }).toPromise() as HttpResponse<string>;

      if (response.status !== 200) {
        throw new Error(`Failed to fetch authorization endpoint: ${response.statusText}`);
      }

      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('text/html')) {
        const parser = new DOMParser();
        const htmlDocument = parser.parseFromString(response.body, 'text/html');
        const formElement = htmlDocument.querySelector('form');
  
        if (formElement) {
          const codeInput = formElement.querySelector('input[name="code"]');
          const code = codeInput ? codeInput.getAttribute('value') : null;
  
          if (code) {
            return code;
          } else {
            throw new Error('Authorization code not found in the HTML response');
          }
        }
      }
  
      throw new Error('Invalid response format');
    } catch (error) {
      throw error;
    }
  }

  private async generatePKCEParams(): Promise<{ nonce: string, state: string, codeVerifier: string, codeChallenge: string }> {
    const nonce = this.generateRandomString(60);
    const state = this.generateRandomString(60);
    const codeVerifier = this.generateRandomString(60);
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);

    return { nonce, state, codeVerifier, codeChallenge };
  }

  private async generateCodeChallenge(codeVerifier: string): Promise<string> {
    const hashedCodeVerifier = await this.sha256(codeVerifier);
    return this.base64urlEncode(hashedCodeVerifier);
  }

  private base64urlEncode(str: string): string {
    const matches = str.match(/.{1,2}/g);
    if (matches) {
      const binaryString = matches
        .map(byte => String.fromCharCode(parseInt(byte, 16)))
        .join('');
      return btoa(binaryString)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
    } else {
      throw new Error('No matches found in the input string');
    }
  }

  private async sha256(plain: string): Promise<string> {
    const utf8Encoder = new TextEncoder();
    const data = utf8Encoder.encode(plain);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  }

  private generateRandomString(length: number): string {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    const randomValues = new Uint32Array(length);
    window.crypto.getRandomValues(randomValues);

    for (let i = 0; i < length; i++) {
      result += charset[randomValues[i] % charset.length];
    }

    return result;
  }
}
