import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError, tap } from 'rxjs/operators';

/*
    adds extension .json to urls to mock resources (containing paths in environment.mockPath)
    for requests with query string:
        first extends the name .../myurl?query=666 -> .../myurl_query_666.json
        if 404, uses .../myurl.json?query=666
*/
const MOCK_ERROR = 'Mock Error';
@Injectable()
export class MockInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const originalUrl = req.url;
        const mockErrorKeys = Object.keys(environment.mockError);
        // first check if we should mock an error
        if (mockErrorKeys.some((k: string) => originalUrl.includes(environment[k]))) {
            const key = mockErrorKeys.find((k: string) => originalUrl.includes(environment[k]));
            const status = environment.mockError[key];
            console.warn('MOCK ERROR for', key, originalUrl, `set status:${status}`);
            const errorMock = {
                status,
                statusText: MOCK_ERROR,
                url: originalUrl,
                ok: false,
                name: 'HttpErrorResponse',
                message: `Http failure response for ${originalUrl}: ${status} ${MOCK_ERROR}`,
                error: MOCK_ERROR
            };
            // throw an error and provide status
            return throwError(new HttpErrorResponse(errorMock));
        } else if (environment.mockPath.some((pattern: string) => originalUrl.includes(pattern))
            && !originalUrl.includes('.json')
        ) {
            // try variations to mock url
            // if substring in url contained in mockPath list (unless explicitly ends with .json)
            const mockUrls = [];
            const hasQS = originalUrl.indexOf('?') > -1;
            const substitutedUrl = originalUrl.replace(/([|=,]|%2B)/g, '_').replace(/ /g, '-') ;
            console.warn('MOCKING URL SUB', originalUrl, substitutedUrl);
            if (hasQS) {
                // has a query string, build the json filename from parameters
                mockUrls.push(`${substitutedUrl.replace(/(\?)/g, '_')}.json`);
            }
            // add .json, allowing for query string
            const arr = substitutedUrl.split('?');
            arr[0] += '.json';
            const urlJson = arr.join('?');
            mockUrls.push(urlJson);
            // if anything fails use fixed default.json
            const arr2 = originalUrl.split('/');
            arr2[arr2.length - 1] = 'default.json'; // fallback file
            mockUrls.push(arr2.join('/'));
            const mockUrlsCopy = [...mockUrls];
            console.warn('MOCKING URL', originalUrl, mockUrlsCopy);

            let url = mockUrls.shift();
            const handler = (request: HttpRequest<any>) => next.handle(request).pipe(
                tap((r: HttpEvent<any>) => {
                    if (r.type !== 0) { console.warn('MOCKING URL', originalUrl, 'SUCCESS:', (r as any).url); }
                }),
                catchError((e: HttpErrorResponse) => {
                    if (mockUrls.length === 0) {
                        const body = { url: originalUrl, error: `Failure to mock url '${originalUrl}'!`, attempted: mockUrlsCopy };
                        console.warn('MOCKING URL FAILED', body);
                        return throwError(e);
                    } else {
                        url = mockUrls.shift();
                        return handler(request.clone({ url }));
                    }
                }));
            return handler(req.clone({ url }));
        } else {
            // no action (.../myurl)
            return next.handle(req);
        }
    }
}
